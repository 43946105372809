import axios from 'axios';
// import { store } from './../store';
// import { unsetUser } from './../actions/auth';
import { apiBasePath } from './../configs/urls';

axios.defaults.withCredentials = true;

let api = axios.create({
	baseURL: apiBasePath + '/'
});

api.interceptors.request.use(
	(res: any) => {

		return res;
	},

	(err: any) => {
		return Promise.reject(err);
	}
);

api.interceptors.response.use(
	(res: any) => {

		return res;
	},

	(err: any) => {
		if (err && err.response && err.response.status === 401) {
			// store.dispatch<any>(unsetUser());
		}

		return Promise.reject(err);
	}
);

export default api;