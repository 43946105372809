import React from 'react';

// Import styles
import './toggler.scss';

interface props {
	'value': boolean,
	'checkedText': string,
	'uncheckedText': string,
	'onChange': any
};

const Toggler = (props: props) => {
	return (
		<label className="TOGGLER">
			<input className="toggle-container" type="checkbox" onChange={() => props.onChange(!props.value)} checked={props.value} />
			
			<div className="toggle-switch"></div>
			
			{
				props.checkedText &&
				<div className="checked-text">
					{ props.checkedText }
				</div>
			}

			{
				props.uncheckedText &&
				<div className="unchecked-text">
					{ props.uncheckedText }
				</div>
			}
		</label>
	);
}

export default Toggler;
