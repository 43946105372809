import { STATE_RESET, SET_CURRENT_USER } from './../actions/types';

const initialState: {[key: string]: any} = {
	'isAuthenticated': false,
	'toggleUpdate': false,
	'id': '',
	'email': '',
	'firstName': '',
	'lastName': '',
	'isEmailConfirmed': false,
	'credits': 0,
	'isDarkThemeEnabled': false,
	'creditsUpdatedAt': null,
	'availableNetworks': 0,
	'availableProfiles': 0,
	'profilesCount': 0,
	'mainProfile': {},
	'invitationCodeShareable': '',
	'isEmailPromotionsAllowed': false,
	'isEmailAppUpdatesAllowed': false,
	'isEmailMessageNotificationsAllowed': false,
	'showGuide': false,
	'newMessages': 0,
	'newLogs': 0,
	'showReferralsPopup': false,
	'globalPopupId': '',
	'unlockedPostsEndsAt': null
};

const updatedState = (state: {[key: string]: any} = initialState, action: {[key: string]: any} = {}) => {
	let data: {[key: string]: any} = {
		'isAuthenticated': action['isAuthenticated'],
		'toggleUpdate': action['toggleUpdate'],
		'id': action['id'],
		'email': action['email'],
		'firstName': action['firstName'],
		'lastName': action['lastName'],
		'isEmailConfirmed': action['isEmailConfirmed'],
		'credits': action['credits'],
		'isDarkThemeEnabled': action['isDarkThemeEnabled'],
		'creditsUpdatedAt': action['creditsUpdatedAt'],
		'availableNetworks': action['availableNetworks'],
		'availableProfiles': action['availableProfiles'],
		'profilesCount': action['profilesCount'],
		'mainProfile': action['mainProfile'],
		'invitationCodeShareable': action['invitationCodeShareable'],
		'isEmailPromotionsAllowed': action['isEmailPromotionsAllowed'],
		'isEmailAppUpdatesAllowed': action['isEmailAppUpdatesAllowed'],
		'isEmailMessageNotificationsAllowed': action['isEmailMessageNotificationsAllowed'],
		'showGuide': action['showGuide'],
		'newMessages': action['newMessages'],
		'newLogs': action['newLogs'],
		'showReferralsPopup': action['showReferralsPopup'],
		'globalPopupId': action['globalPopupId'],
		'unlockedPostsEndsAt': action['unlockedPostsEndsAt']
	}

	if (action['adminType']) {
		data['adminType'] = action['adminType'];
	}

	switch(action['type']) {

		case STATE_RESET: return initialState;

		case SET_CURRENT_USER: return data;
		
		default: return state;
	}
}

export default updatedState;