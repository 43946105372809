import React, { useState } from 'react';

// Import styles
import './input.scss';

interface props {
	'value': string,
	'name'?: string,
	'placeholder': string,
	'icon'?: string,
	'label'?: string,
	'labelHelp'?: any,
	'type': string,
	'disabled'?: boolean,
	'errors'?: any,
	'onChange': any,
	'onFocus'?: any
	'onBlur'?: any,
	'setNotifications'?: any
};

const Input = (props: props) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

	const onChangeInputType = () => {
		if (!props.type) { return; }

		setIsPasswordVisible(!isPasswordVisible);
	}

	return (
		<div className={'INPUT' + (props.errors ? ' error' : '')}>
			{
				props.label &&
				<div className="INPUT__label">
					<label htmlFor={props.name}>
						{ props.label }
						{
							props.labelHelp !== undefined &&
							<span onClick={() => props.setNotifications(props.labelHelp)} className="icon-interrogation-regular"></span>
						}
					</label>
				</div>
			}

			<div className={'INPUT__input' + (props.type === 'password' ? ' password' : '') + (props.icon ? ' has-icon' : '')}>
				{
					props.icon &&
					<div className="INPUT__input-icon">
						<span className={props.icon}></span>
					</div>
				}
				
				<input
					name={props.name}
					placeholder={props.placeholder}
					onChange={(e) => props.onChange(e.target.value)}
					type={props.type ? (props.type === 'password' && isPasswordVisible ? 'text' : props.type) : 'text'}
					value={props.value}
					autoComplete="none"
					disabled={props.disabled}
					onFocus={(val: any) => (props.onFocus ? props.onFocus(val) : null)}
					onBlur={(val: any) => (props.onBlur ? props.onBlur(val) : null)}
				/>

				{
					(props.type === 'password') &&
					<span onClick={() => onChangeInputType()} className={'INPUT__input-showpass' + (isPasswordVisible ? ' active' : '')}>
						{
							!isPasswordVisible &&
							<span className="icon-eye-crossed-regular"></span>
						}

						{
							isPasswordVisible &&
							<span className="icon-eye-regular"></span>
						}
					</span>
				}
			</div>

			{
				props.errors &&
				<p className="error">
					{ props.errors }
				</p>
			}
		</div>
	);
}

export default Input;

// export default React.memo(Input, (prevProps, nextProps) => {
// 	if (prevProps.value === nextProps.value) { return true; }
// 	return false;
// });