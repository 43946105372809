import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import configs
import { apiBasePath } from './../../other/configs/urls';

// Import components
import ProgressiveImg from './../progressive-img/progressive-img';

// Import styles
import './navigation.scss';

interface props {}

const Navigation = (props: props) => {
	const location = useLocation();
	const navigate = useNavigate();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	// Check if email is confirmed, if not, redirect to confirmation page
	useEffect(() => {
		if (
			auth.isAuthenticated && 
			!auth.isEmailConfirmed &&
			location.pathname !== slugByPageId('email-confirmation', lang, routes) &&
			location.pathname !== slugByPageId('settings', lang, routes) &&
			location.pathname !== slugByPageId('about1', lang, routes) &&
			location.pathname !== slugByPageId('faq', lang, routes) &&
			location.pathname !== slugByPageId('terms', lang, routes) &&
			location.pathname !== slugByPageId('privacy', lang, routes) &&
			location.pathname !== slugByPageId('cookies', lang, routes) &&
			location.pathname !== slugByPageId('decline-cookies', lang, routes) &&
			location.pathname !== slugByPageId('not-found', lang, routes) &&
			location.pathname !== slugByPageId('qr', lang, routes) &&
			location.pathname !== slugByPageId('rules', lang, routes)
		) {
			navigate(slugByPageId('email-confirmation', lang, routes), {'replace': true});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	let mainProfileImage: string = '';

	if (auth.mainProfile?.images?.[0]?.path && auth.isAuthenticated) {
		mainProfileImage = apiBasePath + '/uploads/' + auth.mainProfile?.images[0].path;
	}

	return (
		<div className="NAVIGATION">
			<div className="grid">
				<div className="row">
					<div className="col-12">
						<div className="NAVIGATION__scroll">
							<div className="NAVIGATION__inner">
								<div className="NAVIGATION__menu">
									{
										_.map(generalText.navigation, (val1, i1) => {
											// if (!auth.isAuthenticated && val1.isRestricted) { return false; }

											if (!val1.isUserProfiles) {
												return (
													<div key={i1} className={'NAVIGATION__menu-item' + (
															(auth.isAuthenticated && !auth.isEmailConfirmed) ||
															(!auth.isAuthenticated && val1.isRestricted) ? ' disabled' : ''
														)}>
														<NavLink 
															to={val1.hasMainProfileId ? slugByPageId(val1.link, lang, routes).replace(':id', auth.mainProfile?.id) : slugByPageId(val1.link, lang, routes)}
															className={(navData: {[key: string]: any}) => navData.isActive ? 'link active' : 'link'}
														>
															{
																val1.icon &&
																<span className={val1.icon}></span>
															}
															
															{
																val1.text &&
																<span className="text">{ val1.text }</span>
															}

															{
																val1.hasNewMessagesBadge &&
																auth.newMessages > 0 && 
																<span className="badge">{ auth.newMessages }</span>
															}

															{
																val1.hasNewActionsBadge &&
																auth.newLogs > 0 && 
																<span className="badge">{ auth.newLogs }</span>
															}
														</NavLink>
													</div>
												)
											}

											if (val1.isUserProfiles && auth.isAuthenticated) {
												return (
													<div key={i1} className={'NAVIGATION__menu-item' + (!auth.isEmailConfirmed ? ' disabled' : '')}>
														<NavLink
															to={slugByPageId(val1.link, lang, routes)} 
															className={(navData: {[key: string]: any}) => navData.isActive ? 'link active' : 'link'}
														>
															<span className={'PROFILEIMAGE diff1 has-animation' + (
																(
																	auth.mainProfile?.slug || 
																	location.pathname === slugByPageId('user-profiles', lang, routes) || 
																	location.pathname === slugByPageId('create-profile', lang, routes) || 
																	location.pathname === slugByPageId('edit-profile', lang, routes) ||
																	!auth.isEmailConfirmed
																) ? ' disabled-animation' : '')}
															>
																<span className="PROFILEIMAGE__inner">
																	{
																		val1.icon &&
																		<span className={val1.icon}></span>
																	}

																	<div className="image">
																		<ProgressiveImg
																			img={false}
																			showLoader={true}
																			shouldLoad={true}
																			lowQualitySrc=""
																			highQualitySrc={mainProfileImage}
																		/>
																	</div>
																</span>
															</span>
														</NavLink>
													</div>
												)
											}
										})
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Navigation;