// Import types
import { SET_ROUTES, STATE_RESET } from './types';

export const setRoutes = (data: {[key: string]: any}) => {
	return {
		'type': SET_ROUTES,
		...data
	};
}

export const resetRoutes = () => {
	return {
		'type': STATE_RESET
	};
}