import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Controller, Scene } from 'react-scrollmagic';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';

// Import helpers
import { validateFields } from './../../other/helpers/validate-fields';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import configs
import { apiBasePath } from './../../other/configs/urls';

// Import components
import Textarea from './../../components/textarea/textarea';
import Popup from './../../components/popup/popup';
import Loader from './../../components/loader/loader';
import PageHeader from './../../components/page-header/page-header';
import ProgressiveImg from './../../components/progressive-img/progressive-img';

// Import styles
import './conversation.scss';

interface props {
	'pageId': string
}

const Conversation = (props: props) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initMessage: {[key: string]: any} = {'message': ''};
	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};
	
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [text, setText] = useState<{[key: string]: any}>({});

	const [message, setMessage] = useState<{[key: string]: any}>(initMessage);

	const [messages, setMessages] = useState<Array<any>>([]);
	const [messagesPage, setMessagesPage] = useState<number>(1);
	const [messagesTotal, setMessagesTotal] = useState<number>(0);

	const [errors, setErrors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
			onGetMessages(1);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	const onGetMessages = useCallback((page: number) => {
		setIsLoading(true);

		// Reinit state if this is the first page
		if (page === 1) {
			setMessages([]);
			setMessagesPage(1);
			setMessagesTotal(0);
		}

		api.get('/get-user-conversation/' + id + '/' + page).then((res1) => {
			setIsLoading(false);
			setMessages(prevState => ([...res1.data.entries, ...prevState]));
			setMessagesPage(page + 1);

			// Get count of total posts and first post's date from DB
			if (page === 1) {
				setMessagesTotal(res1.data?.total || 0);
			}

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {
			if (err.response) {
				navigate(slugByPageId('not-found', lang, routes), {'replace': true});
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, id, navigate]);

	const onSubmitMessage = () => {
		setIsLoading(true);

		let stateClone: any = _.cloneDeep(message);

		// Validation
		let fieldErrors = validateFields(stateClone, ['conversation']);

		if (!_.isEmpty(fieldErrors)) {
			setIsLoading(false);
			setErrors(fieldErrors);

			return;
		}

		// Get senders profile slug
		let senderProfileSlug: string = auth.id === messages?.[0]?.senderUserId ? messages?.[0]?.senderProfile?.slug : messages?.[0]?.receiverProfile?.slug;

		// Get receivers profile id
		let receiverProfileId: string = auth.id !== messages?.[0]?.senderUserId ? messages?.[0]?.senderProfile?._id : messages?.[0]?.receiverProfile?._id;

		let messageData = {
			'senderProfileSlug': senderProfileSlug,
			'receiverProfileId': receiverProfileId,
			'message': message.message
		}

		api.post('/add-message', messageData).then((res) => {
			setIsLoading(false);
			setMessage(initMessage);

			onGetMessages(1);

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	}

	if (_.isEmpty(text)) { return null; }

	return (
		<div className="CONVERSATION">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
			
			<Loader active={isLoading} fixed={true} />

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #85784563422']}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				<PageHeader heading={text.pageTitle} />

				<div className="SECTION has-top-border">
					<div className="grid">
						<div className="row">
							<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
								<div className="row">
									{
										messages?.length > 0 && 
										(messagesTotal > messages.length) && 
										text.conversation?.footer?.button1?.text &&
										<div className="col-6 push-3">
											<button onClick={() => onGetMessages(messagesPage)} className="BUTTON diff2">{ text.conversation?.footer?.button1?.text }</button>		
										</div>
									}
									
									{
										(messagesTotal <= messages.length) && 
										text.conversation?.footer?.button1?.disabledText &&
										<div className="col-6 push-3">
											<button className="BUTTON diff1" disabled={true}>{ text.conversation?.footer?.button1?.disabledText }</button>
										</div>
									}

									{
										_.map(messages, (val1, i1) => {
											let senderProfileImage: string = '';

											if (val1.senderProfile?.images?.[0]?.path) {
												senderProfileImage = apiBasePath + '/uploads/' + val1.senderProfile?.images?.[0]?.path;
											}

											return (
												<div key={i1} className="col-12">
													<div className="SECTION__item">
														<div className={'MESSAGE' + (auth.id === val1.receiverUserId ? ' reversed text-left' : '')}>
															<div className="grid">
																<div className="row">
																	<div className="col-12">
																		
																		{
																			(
																				val1.senderProfile && 
																				val1.senderProfile?.isVisible && 
																				val1.senderProfile?.status === 1
																			) &&
																			<div className={'PROFILEIMAGE' + (!val1.senderProfile?.slug ? ' diff1' : '')}>
																				{
																					val1.senderProfile?.slug &&
																					<Link to={slugByPageId('profile', lang, routes).replace(':slug', val1.senderProfile?.slug)} className="LINK"></Link>
																				}

																				<span className="PROFILEIMAGE__inner">
																					<span className={generalText.profileItem?.hiddenProfile?.icon}></span>
																					<Scene>
																						{(progress: any, event: any) => (
																							<div className="image">
																								<ProgressiveImg
																									img={false}
																									showLoader={true}
																									shouldLoad={event.type === 'start'}
																									lowQualitySrc=""
																									highQualitySrc={senderProfileImage}
																								/>
																							</div>
																						)}
																					</Scene>
																				</span>
																			</div>
																		}

																		{
																			(
																				!val1.senderProfile || 
																				!val1.senderProfile?.isVisible || 
																				val1.senderProfile?.status !== 1
																			) &&
																			<div className="PROFILEIMAGE diff1">
																				<span className="PROFILEIMAGE__inner">
																					<span className={generalText.profileItem?.hiddenProfile?.icon}></span>
																				</span>
																			</div>
																		}

																		<div className="MESSAGE__content">
																			<div className="MESSAGE__content-header">
																				{
																					(
																						val1.senderProfile &&
																						val1.senderProfile?.isVisible && 
																						val1.senderProfile?.status === 1 &&
																						val1.senderProfile?.name
																					) &&
																					<Link to={slugByPageId('profile', lang, routes).replace(':slug', val1.senderProfile?.slug)} className="LINK">{ val1.senderProfile?.name }</Link>
																				}

																				{
																					(
																						!val1.senderProfile || 
																						!val1.senderProfile?.isVisible || 
																						val1.senderProfile?.status !== 1 ||
																						!val1.senderProfile?.name
																					) &&
																					<div>Hidden Profile</div>
																				}

																				{
																					val1.createdAt &&
																					<div className="MESSAGE__content-infobar">
																						<span>
																							{ moment(val1.createdAt).fromNow() }
																						</span>
																					</div>
																				}
																			</div>

																			{
																				val1.message &&
																				<div className="MESSAGE__content-body">
																					<p>
																						{ val1.message }
																					</p>
																				</div>
																			}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											)
										})
									}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="SECTION has-top-border">
					<div className="grid">
						<div className="row">
							<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
								<div className="row">
									<div className="col-12">
										<div className="CONVERSATION__message">
											<div className="PROFILEIMAGE diff1">
												<Link to={slugByPageId('user-profiles', lang, routes)} className="LINK"></Link>

												<span className="PROFILEIMAGE__inner">
													<span className={generalText.profileItem?.hiddenProfile?.icon}></span>
													
													<Scene>
														{(progress: any, event: any) => (
															<div className="image">
																<ProgressiveImg
																	img={false}
																	showLoader={true}
																	shouldLoad={event.type === 'start'}
																	lowQualitySrc=""
																	highQualitySrc={(
																		(
																			messages?.[0]?.senderProfile?.images?.[0]?.path &&
																			messages?.[0]?.receiverProfile?.images?.[0]?.path
																		) ?
																		(
																			auth.id === messages?.[0]?.senderUserId ? 
																			apiBasePath + '/uploads/' + messages?.[0]?.senderProfile?.images?.[0]?.path : 
																			apiBasePath + '/uploads/' + messages?.[0]?.receiverProfile?.images?.[0]?.path
																		) : ''
																	)}
																/>
															</div>
														)}
													</Scene>
												</span>
											</div>

											<div className="CONVERSATION__message-input">
												<div className={'FIELD' + (errors.message ? ' error' : '')}>
													<Textarea
														value={message.message}
														placeholder="Enter text..."
														onChange={(val: string) => setMessage(prevState => ({...prevState, 'message': val}))}
													/>

													{
														errors.message &&
														<p className="error">
															{ errors.message }
														</p>
													}
												</div>
											</div>
										</div>
									</div>

									{
										text.button2?.text &&
										<div className="col-4 push-8">
											<button onClick={() => onSubmitMessage()} className="BUTTON">{ text.button2?.text }</button>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Controller>
		</div>
	);
}

export default Conversation;