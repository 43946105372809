import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// Import middlewares
import api from './../../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../../other/helpers/route-finder';

// Import components
import PageHeader from './../../../components/page-header/page-header';
import Loader from './../../../components/loader/loader';
import Pagination from './../../../components/pagination/pagination';
import Popup from './../../../components/popup/popup';
import Textarea from './../../../components/textarea/textarea';

interface props {};

const CmsPosts = (props: props) => {
	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const initPopups: {[key: string]: any} = {
		'entryToEditId': '',
		'postToRemoveId': '',
	}
	const initIds: {[key: string]: any} = {
		'expandedRowId': '',
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [posts, setPosts] = useState<Array<any>>([]);
	const [postsPage, setPostsPage] = useState<number>(1);
	const [postsTotalPages, setPostsTotalPages] = useState<number>(0);

	const [ids, setIds] = useState<{[key: string]: any}>(initIds);
	const [entry, setEntry] = useState<string>('');
	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [errors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	const onGetPosts = useCallback((page: any) => {
		setIsLoading(true);

		if (page === 1) {
			setPosts([]);
			setPostsPage(1);
			setPostsTotalPages(1);
		}

		api.get('/admin-get-posts/' + page).then((res) => {
			setIsLoading(false);
			setPostsPage(page);
			setPosts(res.data.entries);

			if (page === 1 && res.data?.total) {
				setPostsTotalPages(Math.round(res.data.total / res.data.entries.length));
			}

		}).catch((err) => {

			setNotifications(err.response?.data?.messages);
			setIsLoading(false);

		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onGetPosts(1);
	}, [onGetPosts]);

	const getPost = (id: number) => {
		setIsLoading(true);
		
		api.get('/admin-get-post/' + id).then((res1) => {				
			// Replaced post with an updated version
			let stateClone = _.cloneDeep(posts);
			let index = _.findIndex(stateClone, {'_id': res1.data[0]._id});

			stateClone[index] = res1.data[0];

			setPosts(stateClone);
			setIsLoading(false);

		}).catch((err) => {

			setIsLoading(false);
			setNotifications(err.response?.data?.messages);

		});
	}

	const onGetEntryJSON = (id: string) => {
		setIsLoading(true);
		
		api.get('/admin-get-post/' + id).then((res) => {

			let editableFields: any = {
				'_id': res.data[0]._id,
				'status': res.data[0].status,
				'statusMessage': res.data[0].statusMessage,
				'kind': res.data[0].kind,
				'country': res.data[0].country,
				'city': res.data[0].city,
				'type': res.data[0].type,
				'category': res.data[0].category,
				'interest': res.data[0].interest,
				'text': res.data[0].text,
				'slug': res.data[0].slug
			}

			setEntry(JSON.stringify(editableFields, undefined, 4));
			setPopups({...initPopups, 'entryToEditId': res.data[0]._id});
			setIsLoading(false);

		}).catch((err) => {

			setIsLoading(false);
			setNotifications(err.response?.data?.messages);

		});
	}

	const onEditPost = async () => {
		setIsLoading(true);

		// Change post status
		api.put('/admin-edit-post', JSON.parse(entry)).then((res) => {

			setIsLoading(false);
			setPopups(initPopups);
			getPost(res.data._id);

		}).catch((err) => {

			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);

		});
	}

	const onRemovePost = async () => {
		// Check admin permissions
		if (auth.adminType !== 1) {
			setPopups(initPopups);
			setNotifications({'text': ['You don\'t have permissions to complete this action']});
		}

		setIsLoading(true);

		// Pass clicked post id depending on the status
		let postData: any = {
			'id': popups.postToRemoveId
		}

		// Remove post
		api.put('/admin-remove-post/', postData).then((res) => {
			
			setIsLoading(false);
			setPopups(initPopups);
			onGetPosts(postsPage);

		}).catch((err) => {

			setIsLoading(false);	
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);

		});
	}

	return (
		<div className="CMSPOSTS">
			<Loader active={isLoading} fixed={true} />

			<Popup
				title="Edit entry"
				submitBtn="Edit entry"
				closeBtn="Close"
				active={popups.entryToEditId !== ''}
				onSubmit={() => onEditPost()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION padding-wrap-sm">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="SECTION__item">
									<div className="FIELD">
										<Textarea
											value={entry}
											label="Status message"
											placeholder="Enter text..."
											errors={errors.entry}
											onChange={(val: string) => setEntry(val)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title="Remove post"
				text={['Are you sure you want to remove this post? This action cannot be undone.']}
				submitBtn="Remove post"
				closeBtn="Close"
				active={popups.postToRemoveId !== ''}
				onSubmit={() => onRemovePost()}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #342635746555']}
				closeBtn="Close"
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<PageHeader heading="Posts" />

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="CMSLAYOUT__table">
								<table>
									<thead>
										<tr>
											<th>
											</th>

											<th>
												<p>Post ID</p>
											</th>

											<th>
												<p>User</p>
											</th>

											<th>
												<p>Profile</p>
											</th>

											<th>
												<p>Kind</p>
											</th>

											<th>
												<p>Slug</p>
											</th>

											<th>
												<p>Text</p>
											</th>

											<th>
												<p>Country</p>
											</th>

											<th>
												<p>City</p>
											</th>

											<th>
												<p>Type</p>
											</th>

											<th>
												<p>Category</p>
											</th>

											<th>
												<p>Interest</p>
											</th>

											<th>
												<p>Status</p>
											</th>

											<th>
												<p>Status Message</p>
											</th>

											<th>
												<p>Created at</p>
											</th>

											<th>
												<p>Updated at</p>
											</th>

											<th>
												<p>Deleted at</p>
											</th>
										</tr>
									</thead>

									<tbody>
										{
											_.map(posts, (val1, i1) => {
												let bg: string = '';

												switch (val1.status) {
													case 0: bg = 'yellow'; break;
													case 1: bg = 'green'; break;
													case 2: bg = 'red'; break;
													default: bg = '';
												}

												return (
													<tr key={i1} className={ids.expandedRowId === i1.toString() ? 'expanded': ''}>
														<td>
															<div className="cell">
																<div className={'color bg-' + bg}></div>

																<button onClick={() => setIds(prevState => ({...initIds, 'expandedRowId': (prevState.expandedRowId === i1.toString() ? '' : i1.toString())}))} className="BUTTON size1">{ ids.expandedRowId === i1.toString() ? 'Collapse' : 'Expand' }</button>
																<button onClick={() => onGetEntryJSON(val1._id)} className="BUTTON size1">Edit entry</button>
																<button onClick={() => setPopups({...initPopups, 'postToRemoveId': val1._id})} className="BUTTON size1">Remove post</button>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1._id }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>
																	<strong>User ID:</strong> { val1.user._id }<br />
																	<strong>User name:</strong> { val1.user.firstName} {val1.user.lastName }<br />
																	<strong>User email:</strong> { val1.user.email }
																</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>
																	<strong>Profile ID:</strong> <Link to={slugByPageId('profile', lang, routes).replace(':slug', val1.profile.slug)}>{val1.profile._id}</Link><br />
																	<strong>Profile name:</strong> { val1.profile.name }
																</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.kind }</p>
															</div>
														</td>
														
														<td className="allow-wrap pre-line">
															<div className="cell">
																<p>{ val1.slug?.[lang] }</p>
															</div>
														</td>
														
														<td className="allow-wrap pre-line">
															<div className="cell">
																<p>{ val1.text?.[lang]?.text }</p>
															</div>
														</td>
														
														<td>
															<div className="cell">
																<p>{ val1.country }</p>
															</div>
														</td>
														
														<td>
															<div className="cell">
																<p>{ val1.city }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.type }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.category }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.interest }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.status }</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<p>{ val1.statusMessage }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.createdAt && moment(val1.createdAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.updatedAt && moment(val1.updatedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.deletedAt && moment(val1.deletedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{
						postsTotalPages > 1 &&
						<div className="row">
							<div className="col-12">
								<Pagination
									currentPage={postsPage}
									totalPages={postsTotalPages}
									prevText="Prev"
									nextText="Next"
									onChangePage={(val: number) => onGetPosts(val)}
								/>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default CmsPosts;