import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

// Import middlewares
import api from './../../../other/middlewares/api';

// Import components
import PageHeader from './../../../components/page-header/page-header';
import Loader from './../../../components/loader/loader';
import Popup from './../../../components/popup/popup';

interface props {};

const CmsDashboard = (props: props) => {
	const initPopups: {[key: string]: any} = {
		'showNotifyTopProfilesPopup': false
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}
	
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [counts, setCounts] = useState<{[key: string]: any}>({});
	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		setIsLoading(true);

		let endPoints = [
			'admin-get-users-count',
			'admin-get-profiles-count',
			'admin-get-unconfirmed-profiles-count',
			'admin-get-posts-count',
			'admin-get-comments-count',
			'admin-get-unconfirmed-posts-count',
			'admin-get-total-reports-count',
			'admin-get-unresolved-reports-count',
			'admin-get-trashed-users-count',
			'admin-get-trashed-profiles-count',
			'admin-get-trashed-posts-count',
			'admin-get-trashed-comments-count',
		]

		let counts: {[key: string]: any} = {};

		_.forEach(endPoints, function(val1, i1) {
			api.get(val1).then((res) => {

				counts = {...counts, ...res.data}

				if (Object.keys(counts).length === endPoints.length) {
					setCounts(counts);
					setIsLoading(false);
				}

			}).catch((err) => {
				setIsLoading(false);
				setNotifications(err.response?.data?.messages);
			});
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onNotifyTopProfiles = () => {
		setIsLoading(true);

		api.post('admin-notify-top-profiles').then((res) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications({
				'title': 'Success!',
				'text': ['Emails have been successfully sent.']
			});
			
		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	return (
		<div className="CMSDASHBOARD">
			<Loader active={isLoading} fixed={true} />

			<Popup
				title="Confirm send"
				text={['Are you sure you want to send top users an email?']}
				submitBtn="Yes"
				closeBtn="No"
				active={popups.showNotifyTopProfilesPopup}
				onSubmit={() => onNotifyTopProfiles()}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #2461234131']}
				closeBtn="Close"
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>
			
			<PageHeader heading="Dashboard" />

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-6 col-md-3">
							<div className="OTHERITEM full-height">
								<div className="OTHERITEM__content">
									<div className="OTHERITEM__body">
										<NavLink to="users" className="OTHERITEM__link LINK"></NavLink>

										<div className="OTHERITEM__icon">
											<span className="icon-user-regular"></span>
										</div>

										<div className="OTHERITEM__text">
											<div className="SECTION__title font-md">
												Users
											</div>

											<div className="SECTION__subtitle font-xs">
												{ counts.totalUsers }
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-6 col-md-3">
							<div className={'OTHERITEM full-height' + (counts.unconfirmedProfiles > 0 ? ' diff1' : '')}>
								<div className="OTHERITEM__content">
									<div className="OTHERITEM__body">
										<NavLink to="profiles" className="OTHERITEM__link LINK"></NavLink>

										<div className="OTHERITEM__icon">
											<span className="icon-playing-cards-regular"></span>
										</div>

										<div className="OTHERITEM__text">
											<div className="SECTION__title font-md">
												Profiles
											</div>

											<div className="SECTION__subtitle font-xs">
												{ counts.totalProfiles } { counts.unconfirmedProfiles > 0 && <strong>({ counts.unconfirmedProfiles })</strong> }
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-6 col-md-3">
							<div className={'OTHERITEM full-height' + (counts.unconfirmedPosts > 0 ? ' diff1' : '')}>
								<div className="OTHERITEM__content">
									<div className="OTHERITEM__body">
										<NavLink to="posts" className="OTHERITEM__link LINK"></NavLink>
									
										<div className="OTHERITEM__icon">
											<span className="icon-megaphone-regular"></span>
										</div>

										<div className="OTHERITEM__text">
											<div className="SECTION__title font-md">
												Posts
											</div>

											<div className="SECTION__subtitle font-xs">
												{ counts.totalPosts } { counts.unconfirmedPosts > 0 && <strong>({ counts.unconfirmedPosts })</strong> }
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-6 col-md-3">
							<div className="OTHERITEM full-height">
								<div className="OTHERITEM__content">
									<div className="OTHERITEM__body">
										<NavLink to="comments" className="OTHERITEM__link LINK"></NavLink>

										<div className="OTHERITEM__icon">
											<span className="icon-comments-regular"></span>
										</div>

										<div className="OTHERITEM__text">
											<div className="SECTION__title font-md">
												Comments
											</div>

											<div className="SECTION__subtitle font-xs">
												{ counts.totalComments }
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-6 col-md-3">
							<div className="OTHERITEM full-height">
								<div className="OTHERITEM__content">
									<div className="OTHERITEM__body">
										<NavLink to="trashed-users" className="OTHERITEM__link LINK"></NavLink>

										<div className="OTHERITEM__icon">
											<span className="icon-info-regular"></span>
										</div>

										<div className="OTHERITEM__text">
											<div className="SECTION__title font-md">
												Trashed users
											</div>

											<div className="SECTION__subtitle font-xs">
												{ counts.trashedUsers }
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-6 col-md-3">
							<div className="OTHERITEM full-height">
								<div className="OTHERITEM__content">
									<div className="OTHERITEM__body">
										<NavLink to="trashed-profiles" className="OTHERITEM__link LINK"></NavLink>

										<div className="OTHERITEM__icon">
											<span className="icon-info-regular"></span>
										</div>

										<div className="OTHERITEM__text">
											<div className="SECTION__title font-md">
												Trashed profiles
											</div>

											<div className="SECTION__subtitle font-xs">
												{ counts.trashedProfiles }
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-6 col-md-3">
							<div className="OTHERITEM full-height">
								<div className="OTHERITEM__content">
									<div className="OTHERITEM__body">
										<NavLink to="trashed-posts" className="OTHERITEM__link LINK"></NavLink>
									
										<div className="OTHERITEM__icon">
											<span className="icon-info-regular"></span>
										</div>

										<div className="OTHERITEM__text">
											<div className="SECTION__title font-md">
												Trashed posts
											</div>

											<div className="SECTION__subtitle font-xs">
												{ counts.trashedPosts }
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-6 col-md-3">
							<div className="OTHERITEM full-height">
								<div className="OTHERITEM__content">
									<div className="OTHERITEM__body">
										<NavLink to="trashed-comments" className="OTHERITEM__link LINK"></NavLink>

										<div className="OTHERITEM__icon">
											<span className="icon-info-regular"></span>
										</div>

										<div className="OTHERITEM__text">
											<div className="SECTION__title font-md">
												Trashed comments
											</div>

											<div className="SECTION__subtitle font-xs">
												{ counts.trashedComments }
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-6 col-md-3">
							<div className={'OTHERITEM full-height' + (counts.unresolvedReports > 0 ? ' diff1' : '')}>
								<div className="OTHERITEM__content">
									<div className="OTHERITEM__body">
										<NavLink to="reports" className="OTHERITEM__link LINK"></NavLink>
									
										<div className="OTHERITEM__icon">
											<span className="icon-info-regular"></span>
										</div>

										<div className="OTHERITEM__text">
											<div className="SECTION__title font-md">
												Reports
											</div>

											<div className="SECTION__subtitle font-xs">
												{ counts.totalReports } { counts.unresolvedReports > 0 && <strong>({ counts.unresolvedReports })</strong> }
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-6 col-md-3">
							<div className="OTHERITEM full-height">
								<div className="OTHERITEM__content">
									<div className="OTHERITEM__body">
										<NavLink to="emails" className="OTHERITEM__link LINK"></NavLink>
									
										<div className="OTHERITEM__icon">
											<span className="icon-envelope-regular"></span>
										</div>

										<div className="OTHERITEM__text">
											<div className="SECTION__title font-md">
												Emails
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-6 col-md-3">
							<div className="OTHERITEM full-height">
								<div className="OTHERITEM__content">
									<div className="OTHERITEM__body">
										<div onClick={() => setPopups({...initPopups, 'showNotifyTopProfilesPopup': true})} className="OTHERITEM__link LINK"></div>
									
										<div className="OTHERITEM__icon">
											<span className="icon-envelope-regular"></span>
										</div>

										<div className="OTHERITEM__text">
											<div className="SECTION__title font-md">
												Send monthly top
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


export default CmsDashboard;