import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// Import middlewares
import api from './../../../other/middlewares/api';

// Import helpers
import { checkCookie } from './../../../other/helpers/cookies';
import { slugByPageId } from './../../../other/helpers/route-finder';

// Import configs
import { apiBasePath } from './../../../other/configs/urls';

// Import components
import PageHeader from './../../../components/page-header/page-header';
import Loader from './../../../components/loader/loader';
import Pagination from './../../../components/pagination/pagination';
import Popup from './../../../components/popup/popup';
import Textarea from './../../../components/textarea/textarea';
import Input from './../../../components/input/input';

interface props {};

const CmsProfiles = (props: props) => {
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const initFilter: {[key: string]: any} = {'searchPhrase': ''}
	const initPopups: {[key: string]: any} = {
		'entryToEditSlug': '',
		'profileToRemoveSlug': '',
	}
	const initIds: {[key: string]: any} = {
		'expandedRowId': '',
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [profiles, setProfiles] = useState<Array<any>>([]);
	const [profilesPage, setProfilesPage] = useState<number>(1);
	const [profilesTotalPages, setProfilesTotalPages] = useState<number>(0);
	
	const [ids, setIds] = useState<{[key: string]: any}>(initIds);
	const [entry, setEntry] = useState<string>('');
	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [filter, setFilter] = useState<{[key: string]: any}>(initFilter);
	const [errors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	const onGetProfiles = useCallback((page: any, searchPhrase: any) => {
		setIsLoading(true);

		if (page === 1) {
			setProfiles([]);
			setProfilesPage(1);
			setProfilesTotalPages(1);
		}

		api.get('/admin-get-profiles/' + page + (searchPhrase ? ('/' + searchPhrase) : '')).then((res) => {

			setIsLoading(false);
			setProfiles(res.data.entries);
			setProfilesPage(page);

			if (page === 1 && res.data?.total) {
				setProfilesTotalPages(Math.ceil(res.data.total / res.data.entries.length));
			}

		}).catch((err) => {

			setIsLoading(false);
			setNotifications(err.response?.data?.messages);

		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onGetProfiles(1, '');
	}, [onGetProfiles]);

	const getProfile = (id: number) => {
		setIsLoading(true);
		
		api.get('/admin-get-profile/' + id).then((res) => {

			// Replaced profile with an updated version
			let stateClone = _.cloneDeep(profiles);
			let index = _.findIndex(stateClone, {'_id': res.data[0]._id});

			stateClone[index] = res.data[0];

			setIsLoading(false);
			setProfiles(stateClone);

		}).catch((err) => {

			setIsLoading(false);
			setNotifications(err.response?.data?.messages);

		});
	}

	const onGetEntryJSON = (slug: string) => {
		setIsLoading(true);
		
		api.get('/admin-get-profile/' + slug).then((res) => {

			let editableFields: any = {
				'_id': res.data[0]._id,
				'status': res.data[0].status,
				'valueRating': res.data[0].valueRating || 0,
				'statusMessage': res.data[0].statusMessage,
				'name': res.data[0].name,
				'slug': res.data[0].slug,
				'country': res.data[0].country,
				'city': res.data[0].city,
				'category': res.data[0].category,
				'raisedAt': res.data[0].raisedAt,
				'types': res.data[0].types,
				'networks': res.data[0].networks
			}

			setIsLoading(false);
			setEntry(JSON.stringify(editableFields, undefined, 4));
			setPopups({...initPopups, 'entryToEditSlug': res.data[0].slug});

		}).catch((err) => {

			setIsLoading(false);
			setNotifications(err.response?.data?.messages);

		});
	}

	const onEditProfile = () => {
		setIsLoading(true);

		// Change profile status
		api.put('/admin-edit-profile', JSON.parse(entry)).then((res) => {
			
			setIsLoading(false);
			setPopups(initPopups);
			getProfile(res.data.slug);

		}).catch((err) => {

			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);

		});
	}

	const onRemoveProfile = () => {
		setIsLoading(true);

		let profileData: any = {
			'slug': popups.profileToRemoveSlug
		}

		// Remove profile
		api.put('/admin-remove-profile', profileData).then((res) => {

			setIsLoading(false);
			setPopups(initPopups);
			onGetProfiles(profilesPage, '');

		}).catch((err) => {

			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);

		});
	}

	return (
		<div className="CMSPROFILES">
			<Loader active={isLoading} fixed={true} />

			<Popup
				title="Edit entry"
				submitBtn="Edit entry"
				closeBtn="Close"
				active={popups.entryToEditSlug !== ''}
				onSubmit={() => onEditProfile()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION padding-wrap-sm">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="SECTION__item">
									<div className="FIELD">
										<Textarea
											value={entry}
											label="Entry JSON"
											placeholder="Enter text..."
											errors={errors.entry}
											onChange={(val: string) => setEntry(val)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title="Remove profile"
				text={['Are you sure you want to remove this profile? This action cannot be undone.']}
				submitBtn="Remove Profile"
				closeBtn="Close"
				active={popups.profileToRemoveSlug !== ''}
				onSubmit={() => onRemoveProfile()}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #56345232334']}
				closeBtn="Close"
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<PageHeader heading="Profiles" />

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12 col-md-6">
							<div className="FIELD diff1">
								<Input
									value={filter.searchPhrase}
									label="Custom search"
									placeholder="Custom search by ID, user email, profile name or slug..."
									type="text"
									errors={errors.searchPhrase}
									onChange={(val: string) => setFilter(prevState => ({...prevState, 'searchPhrase': val}))}
								/>
							</div>
						</div>

						<div className="col-6 col-md-3">
							<button onClick={() => onGetProfiles(1, filter.searchPhrase)} className="BUTTON">Search</button>
						</div>

						<div className="col-6 col-md-3">
							<button onClick={() => onGetProfiles(1, '')} className="BUTTON diff1">Reset Filter</button>
						</div>
					</div>
				</div>	
			</div>
			
			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="CMSLAYOUT__table">
								<table>
									<thead>
										<tr>
											<th>
											</th>

											<th>
												<p>Profile ID</p>
											</th>

											<th>
												<p>User</p>
											</th>

											<th>
												<p>Cover</p>
											</th>

											<th>
												<p>Image</p>
											</th>

											<th>
												<p>Profile name &amp; slug</p>
											</th>

											<th>
												<p>Details</p>
											</th>

											<th>
												<p>Interest</p>
											</th>

											<th>
												<p>Skills</p>
											</th>

											<th>
												<p>About</p>
											</th>

											<th>
												<p>Offer</p>
											</th>

											<th>
												<p>Networks</p>
											</th>

											<th>
												<p>Visible</p>
											</th>

											<th>
												<p>Is unclaimed</p>
											</th>

											<th>
												<p>Status</p>
											</th>

											<th>
												<p>Value rating</p>
											</th>

											<th>
												<p>Status message</p>
											</th>

											<th>
												<p>Created at</p>
											</th>

											<th>
												<p>Updated at</p>
											</th>

											<th>
												<p>Deleted at</p>
											</th>
										</tr>
									</thead>

									<tbody>
										{
											_.map(profiles, (val1, i1) => {
												let coverImage: string = '';

												if (val1.covers?.[0]?.path) {
													coverImage = apiBasePath + '/uploads/' + (checkCookie('webpSupported') ? val1.covers[0]?.path.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : val1.covers[0]?.path);
												}

												let profileImage = '';

												if (val1.images?.[0]?.path) {
													if (val1.isUnclaimed) {
														profileImage = val1.images[0]?.path;
													} else {
														profileImage = apiBasePath + '/uploads/' + (checkCookie('webpSupported') ? val1.images[0]?.path.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : val1.images[0]?.path)
													}
												}
												
												let bg: string = '';

												switch (val1.status) {
													case 0: bg = 'yellow'; break;
													case 1: bg = 'green'; break;
													case 2: bg = 'red'; break;
													default: bg = '';
												}

												return (
													<tr key={i1} className={ids.expandedRowId === i1.toString() ? 'expanded': ''}>
														<td>
															<div className="cell">
																<div className={'color bg-' + bg}></div>

																<button onClick={() => setIds(prevState => ({...initIds, 'expandedRowId': (prevState.expandedRowId === i1.toString() ? '' : i1.toString())}))} className="BUTTON size1">{ ids.expandedRowId === i1.toString() ? 'Collapse' : 'Expand' }</button>
																<button onClick={() => onGetEntryJSON(val1.slug)} className="BUTTON size1">Edit profile</button>
																<button onClick={() => setPopups({...initPopups, 'profileToRemoveSlug': val1.slug})} className="BUTTON size1">Remove profile</button>
															</div>
														</td>

														<td>
															<div className="cell">
																<p><Link to={slugByPageId('profile', lang, routes).replace(':slug', val1.slug)}>{ val1._id }</Link></p>
															</div>
														</td>

														<td>
															<div className="cell">
																{
																	val1.user?._id &&
																	<p>
																		<strong>User ID:</strong> { val1.user?._id }<br />
																		<strong>User name:</strong> { val1.user.firstName } { val1.user.lastName }<br />
																		<strong>User email:</strong> { val1.user.email }
																	</p>
																}

																{
																	!val1.user?._id &&
																	<p>
																		<strong>User ID:</strong> { val1.user?._id }
																	</p>
																}
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<div className="PROFILEIMAGE diff1">
																	<span className="PROFILEIMAGE__inner">
																		<span className="icon-picture-regular"></span>
																		<span className="image" style={{ backgroundImage: 'url('+ coverImage +')' }}></span>
																	</span>
																</div>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<div className="PROFILEIMAGE diff1">
																	<span className="PROFILEIMAGE__inner">
																		<span className="icon-user-solid"></span>
																		<span className="image" style={{ backgroundImage: 'url(' + profileImage + ')' }}></span>
																	</span>
																</div>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>
																	<strong>Name:</strong> { val1.name } <br />
																	<strong>Slug:</strong> { val1.slug }
																</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>
																	<strong>Country:</strong> { val1.country } <br />
																	<strong>City:</strong> { val1.city } <br />
																	<strong>Types:</strong> { val1.types?.join(', ') } <br />
																	<strong>Category:</strong> { val1.category} <br />
																</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<p>{ val1.interests?.join(', ') }</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<p>{ val1.skills?.join(', ') }</p>
															</div>
														</td>

														<td className="allow-wrap pre-line">
															<div className="cell">
																<p>{ val1.about }</p>
															</div>
														</td>

														<td className="allow-wrap pre-line">
															<div className="cell">
																<p>{ val1.offer }</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																{
																	_.map(val1.networks, (val1, i1) => {
																		let url = val1.url;

																		if (!url.match(/^https?:\/\//i)) {
																			url = 'https://' + url;
																		}
																		
																		return (
																			<p key={i1}><strong>{ val1.network }</strong> - <a href={url} target="_blank" rel="noopener noreferrer">{ val1.url }</a></p>
																		)
																	})
																}
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.isVisible ? 'Yes' : 'No' }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.isUnclaimed ? 'Yes' : 'No' }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.status }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.valueRating }</p>
															</div>
														</td>

														<td className="allow-wrap">
															{
																(/<("[^"]*"|'[^']*'|[^'">])*>/.test(val1.statusMessage)) &&
																<div className="cell" dangerouslySetInnerHTML={{ __html: val1.statusMessage }}></div>
															}

															{
																!(/<("[^"]*"|'[^']*'|[^'">])*>/.test(val1.statusMessage)) &&
																<div className="cell">
																	<p>{ val1.statusMessage }</p>
																</div>
															}
														</td>

														<td>
															<div className="cell">
																<p>{ val1.createdAt && moment(val1.createdAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.updatedAt && moment(val1.updatedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.deletedAt && moment(val1.deletedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{
						profilesTotalPages > 1 &&
						<div className="row">
							<div className="col-12">
								<Pagination
									currentPage={profilesPage}
									totalPages={profilesTotalPages}
									prevText="Prev"
									nextText="Next"
									onChangePage={(val: number) => onGetProfiles(val, filter.searchPhrase)}
								/>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default CmsProfiles;