import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import styles
import './footer.scss';

interface props {};

const Footer = (props: props) => {
	// const location = useLocation();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	// if (
	// 	location.pathname !== slugByPageId('about1', lang, routes) &&
	// 	location.pathname !== slugByPageId('faq', lang, routes) &&
	// 	location.pathname !== slugByPageId('settings', lang, routes) &&
	// 	location.pathname !== slugByPageId('terms', lang, routes) &&
	// 	location.pathname !== slugByPageId('privacy', lang, routes) &&
	// 	location.pathname !== slugByPageId('cookies', lang, routes)
	// ) {
	// 	return null;
	// }

	return (
		<footer className="SECTION has-top-border FOOTER">
			<div className="grid">
				<div className="row">
					<div className="col-12">
						<div className="SECTION__item">
							<div className="SECTION__inner bg-diff1 padding-wrap-xl">
								<div className="grid">
									<div className="row">
										<div className="col-12">
											<div className="FOOTER__logo">
												<NavLink 
													to={slugByPageId(generalText?.footer?.logo?.link, lang, routes)}
													className="FOOTER__logo-link"
												>
													{
														generalText?.footer?.logo?.image &&
														(!generalText?.isDarkThemeEnabled && !auth.isDarkThemeEnabled) &&
														<img src={process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/el/' + generalText?.footer?.logo?.image} className="logo" alt={generalText?.footer?.logo?.alt} />
													}
													{
														generalText?.footer?.logo?.image &&
														(generalText?.isDarkThemeEnabled || auth.isDarkThemeEnabled) &&
														<img src={process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/el/' + generalText?.footer?.logo?.imageDiff1} className="logo" alt={generalText?.footer?.logo?.alt} />
													}
													{
														generalText?.footer?.logo?.text1 &&
														<span className="text" dangerouslySetInnerHTML={{ __html: generalText?.footer?.logo?.text1 }}></span>
													}
													{
														generalText?.footer?.logo?.badge &&
														<span className="badge">{ generalText?.footer?.logo?.badge }</span>
													}
												</NavLink>	
											</div>

											{
												generalText?.footer?.text1 &&
												<div className="FOOTER__item" dangerouslySetInnerHTML={{ __html: generalText?.footer?.text1 }}></div>
											}
										</div>

										<div className="col-12">
											<div className="FOOTER__item">
												<div className="FOOTER__item-links">
													{
														_.map(generalText?.footer?.links, (val1, i1) => {
															return (
																<div key={i1} className="FOOTER__item-link">
																	{
																		val1.link &&
																		<Link to={slugByPageId(val1.link, lang, routes)} className="LINK">{ val1.text }</Link>
																	}

																	{
																		val1.url &&
																		<a href={val1.url} className="LINK">{ val1.text }</a>
																	}
																</div>
															)
														})
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</footer>
	);
}

export default Footer;