import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// Import middlewares
import api from './../../../other/middlewares/api';

// Import components
import PageHeader from './../../../components/page-header/page-header';
import Loader from './../../../components/loader/loader';
import Pagination from './../../../components/pagination/pagination';
import Popup from './../../../components/popup/popup';
import Textarea from './../../../components/textarea/textarea';

interface props {};

const CmsComments = (props: props) => {
	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);

	const initPopups: {[key: string]: any} = {
		'entryToEditId': '',
		'commentToRemoveId': '',
	}
	const initIds: {[key: string]: any} = {
		'expandedRowId': '',
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [comments, setComments] = useState<Array<any>>([]);
	const [commentsPage, setCommentsPage] = useState<number>(1);
	const [commentsTotalPages, setCommentsTotalPages] = useState<number>(0);
	const [ids, setIds] = useState<{[key: string]: any}>(initIds);
	const [entry, setEntry] = useState<string>('');
	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [errors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	const onGetComments = useCallback((page: any) => {
		setIsLoading(true);

		if (page === 1) {
			setComments([]);
			setCommentsPage(1);
			setCommentsTotalPages(1);
		}

		api.get('/admin-get-comments/' + page).then((res) => {
			setIsLoading(false);
			setCommentsPage(page);
			setComments(res.data.entries);

			// Add total comments count from DB
			if (page === 1 && res.data?.total) {
				setCommentsTotalPages(Math.round(res.data.total / res.data.entries.length));
			}

		}).catch((err) => {

			setIsLoading(false);
			setNotifications(err.response?.data?.messages);

		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onGetComments(1);
	}, [onGetComments]);

	const getComment = (id: number) => {
		setIsLoading(true);
		
		api.get('/admin-get-comment/' + id).then((res1) => {				
			// Replaced comment with an updated version
			let stateClone = _.cloneDeep(comments);
			let index = _.findIndex(stateClone, {'_id': res1.data[0]._id});

			stateClone[index] = res1.data[0];

			setIsLoading(false);
			setComments(stateClone);

		}).catch((err) => {

			setIsLoading(false);
			setNotifications(err.response?.data?.messages);

		});
	}

	const onGetEntryJSON = (id: string) => {
		setIsLoading(true);
		
		api.get('/admin-get-comment/' + id).then((res) => {

			let editableFields: any = {
				'_id': res.data[0]._id,
				'text': res.data[0].text
			}

			setIsLoading(false);
			setEntry(JSON.stringify(editableFields, undefined, 4));
			setPopups({...initPopups, 'entryToEditId': res.data[0]._id});

		}).catch((err) => {

			setIsLoading(false);
			setNotifications(err.response?.data?.messages);

		});
	}

	const onEditComment = async () => {
		setIsLoading(true);

		// Change comment status
		api.put('/admin-edit-comment', JSON.parse(entry)).then((res) => {

			setIsLoading(false);
			setPopups(initPopups);
			getComment(res.data._id);

		}).catch((err) => {

			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);

		});
	}

	const onRemoveComment = async () => {
		// Check admin permissions
		if (auth.adminType !== 1) {
			setPopups(initPopups);
			setNotifications({'text': ['You don\'t have permissions to complete this action']});
		}

		setIsLoading(true);

		// Pass clicked comment id depending on the status
		let commentData: any = {
			'id': popups.commentToRemoveId
		}

		// Remove comment
		api.put('/admin-remove-comment/', commentData).then((res) => {
			
			setIsLoading(false);
			setPopups(initPopups);
			onGetComments(commentsPage);

		}).catch((err) => {

			setIsLoading(false);	
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);

		});
	}

	return (
		<div className="CMSCOMMENTS">
			<Loader active={isLoading} fixed={true} />

			<Popup
				title="Edit entry"
				submitBtn="Edit entry"
				closeBtn="Close"
				active={popups.entryToEditId !== ''}
				onSubmit={() => onEditComment()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION padding-wrap-sm">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="SECTION__item">
									<div className="FIELD">
										<Textarea
											value={entry}
											label="Status message"
											placeholder="Enter text..."
											errors={errors.entry}
											onChange={(val: string) => setEntry(val)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title="Remove comment"
				text={['Are you sure you want to remove this comment? This action cannot be undone.']}
				submitBtn="Remove comment"
				closeBtn="Close"
				active={popups.commentToRemoveId !== ''}
				onSubmit={() => onRemoveComment()}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #342635746555']}
				closeBtn="Close"
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<PageHeader heading="Comments" />

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="CMSLAYOUT__table">
								<table>
									<thead>
										<tr>
											<th>
											</th>

											<th>
												<p>Comment ID</p>
											</th>

											<th>
												<p>Post ID</p>
											</th>

											<th>
												<p>User IDs</p>
											</th>

											<th>
												<p>Profile IDs</p>
											</th>

											<th>
												<p>Text</p>
											</th>

											<th>
												<p>Created at</p>
											</th>

											<th>
												<p>Updated at</p>
											</th>

											<th>
												<p>Deleted at</p>
											</th>
										</tr>
									</thead>

									<tbody>
										{
											_.map(comments, (val1, i1) => {

												return (
													<tr key={i1} className={ids.expandedRowId === i1.toString() ? 'expanded': ''}>
														<td>
															<div className="cell">
																<button onClick={() => setIds(prevState => ({...initIds, 'expandedRowId': (prevState.expandedRowId === i1.toString() ? '' : i1.toString())}))} className="BUTTON size1">{ ids.expandedRowId === i1.toString() ? 'Collapse' : 'Expand' }</button>
																<button onClick={() => onGetEntryJSON(val1._id)} className="BUTTON size1">Edit entry</button>
																<button onClick={() => setPopups({...initPopups, 'commentToRemoveId': val1._id})} className="BUTTON size1">Remove comment</button>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1._id }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.postId }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>Sender: { val1.senderUserId }</p>
																<p>Receiver: { val1.receiverUserId }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>Sender: { val1.senderProfileId }</p>
																<p>Receiver: { val1.senderProfileId }</p>
															</div>
														</td>
														
														<td className="allow-wrap pre-line">
															<div className="cell">
																<p>{ val1.text?.[lang]?.text }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.createdAt && moment(val1.createdAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.updatedAt && moment(val1.updatedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.deletedAt && moment(val1.deletedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{
						commentsTotalPages > 1 &&
						<div className="row">
							<div className="col-12">
								<Pagination
									currentPage={commentsPage}
									totalPages={commentsTotalPages}
									prevText="Prev"
									nextText="Next"
									onChangePage={(val: number) => onGetComments(val)}
								/>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default CmsComments;