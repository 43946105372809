import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { thunk } from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';

import monitorReducersEnhancer from './monitor-reducers';
import loggerMiddleware from './logger';

// Import reducers
import lang from './lang';
import routes from './routes';
import generalText from './general-text';
import auth from './auth';
import filter from './filter';
import general from './general';

const configureStore = (preloadedState: any) => {
	const middlewares = [loggerMiddleware, thunk];
	const middlewareEnhancer = applyMiddleware(...middlewares);

	const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
	const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

	const composedEnhancers = composeEnhancers(...enhancers);

	const rootReducer = combineReducers({
		lang,
		routes,
		generalText,
		auth,
		filter,
		general,
	});

	const store = createStore(rootReducer, preloadedState, composedEnhancers);

	return store;
};

export default configureStore;


// import { applyMiddleware, createStore, combineReducers } from 'redux';
// import { thunk } from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';

// import monitorReducersEnhancer from './monitor-reducers';
// import loggerMiddleware from './logger';

// // Import reducers
// import lang from './lang';
// import routes from './routes';
// import generalText from './general-text';
// import auth from './auth';
// import filter from './filter';
// import general from './general';

// const configureStore = (preloadedState: any) => {
// 	const middlewares: any = [loggerMiddleware, thunk]
// 	const middlewareEnhancer: any = applyMiddleware(...middlewares)

// 	const enhancers: any = [middlewareEnhancer, monitorReducersEnhancer]
// 	const composedEnhancers: any = composeWithDevTools(...enhancers)

// 	const store: any = createStore(combineReducers({
// 		lang,
// 		routes,
// 		generalText,
// 		auth,
// 		filter,
// 		general
// 	}), preloadedState, composedEnhancers)

// 	return store;
// }

// export default configureStore;



// // Simplified store with @reduxjs/toolkit
// import { configureStore } from '@reduxjs/toolkit';

// // Import reducers
// import lang from './lang';
// import routes from './routes';
// import generalText from './general-text';
// import auth from './auth';
// import filter from './filter';

// const store: any = configureStore({
// 	reducer: {
// 		lang,
// 		routes,
// 		generalText,
// 		auth,
// 		filter
// 	},
// });

// export default store;