import React, { useState, useEffect } from 'react';

// Import components
import Loader from './../loader/loader';

// Import helpers
import { checkCookie } from './../../other/helpers/cookies';

import './progressive-img.scss';

interface props {
	'img': boolean,
	'noAnimation'?: boolean,
	'alt'?: string,
	'showLoader'?: boolean,
	'shouldLoad': boolean,
	'lowQualitySrc': string,
	'highQualitySrc': string,
	'children'?: any
}

function ProgressiveImg(props: props) {
	const [src, setSrc] = useState(props.lowQualitySrc);

	const updatedHighQualitySrc = (checkCookie('webpSupported') ? props.highQualitySrc.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : props.highQualitySrc);

	useEffect(() => {
		if (!props.shouldLoad) {
			return;
		}

		let isUnmounted: boolean = false;
		const img: any = new Image();
		img.src = updatedHighQualitySrc;
		
		img.onload = () => {
			if (isUnmounted) { return; }
			setSrc(updatedHighQualitySrc);
		};
		
		return () => {
			isUnmounted = true;
		};
	}, [props.lowQualitySrc, updatedHighQualitySrc, props.shouldLoad]);
	
	return (
		<span className={
			'PROGRESSIVEIMG' + 
			(src === props.lowQualitySrc ? '' : ' active') + 
			(props.noAnimation ? ' no-animation' : '') +
			(props.img ? ' image' : ' background')
		}>
			{
				props.showLoader &&
				<Loader
					fixed={false}
					active={updatedHighQualitySrc !== '' && src === props.lowQualitySrc}
					noBg={true}
				/>
			}

			{
				props.img &&
				<img src={src} alt={props.alt} className="PROGRESSIVEIMG__item img" />
			}

			{
				!props.img &&
				<span style={{ backgroundImage: 'url(' + src + ')' }} className="PROGRESSIVEIMG__item bg">
					{ props.children }
				</span>
			}
		</span>
	)
};

export default ProgressiveImg;