import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';

// Import middlewares
import api from './../../../other/middlewares/api';

// Import components
import PageHeader from './../../../components/page-header/page-header';
import Loader from './../../../components/loader/loader';
import Pagination from './../../../components/pagination/pagination';
import Popup from './../../../components/popup/popup';

interface props {};

const CmsReports = (props: props) => {
	const initIds: {[key: string]: any} = {
		'expandedRowId': ''
	}
	const initPopups: {[key: string]: any} = {
		'reportToResolveId': '',
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}
	
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [reports, setReports] = useState<Array<any>>([]);
	const [reportsPage, setReportsPage] = useState<number>(1);
	const [reportsTotalPages, setReportsTotalPages] = useState<number>(0);

	const [ids, setIds] = useState<{[key: string]: any}>(initIds);
	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	const onGetReports = useCallback((page: any) => {
		setIsLoading(true);

		if (page === 1) {
			setReports([]);
			setReportsPage(1);
			setReportsTotalPages(1);
		}

		api.get('/admin-get-reports/' + page).then((res) => {
			setIsLoading(false);
			setReportsPage(page);
			setReports(res.data.entries);

			if (page === 1 && res.data?.total) {
				setReportsTotalPages(Math.ceil(res.data.total / res.data.entries.length));
			}

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onGetReports(1);
	}, [onGetReports]);

	const getReport = (id: number) => {
		setIsLoading(true);
		
		api.get('/admin-get-report/' + id).then((res) => {
			// Replaced user with an updated version
			let stateClone = _.cloneDeep(reports);
			let index = _.findIndex(stateClone, {'_id': res.data._id});

			stateClone[index].isResolved = res.data.isResolved;

			setIsLoading(false);
			setReports(stateClone);

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onToggleResolveReport = () => {
		setIsLoading(true);

		let reportData: any = {
			'id': popups.reportToResolveId
		}

		// Remove user
		api.put('/admin-toggle-resolve-report', reportData).then((res) => {
			getReport(popups.reportToResolveId);
			setIsLoading(false);
			setPopups(initPopups);

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	return (
		<div className="CMSREPORTS">
			<Loader active={isLoading} fixed={true} />

			<Popup
				title="Toggle resolve report"
				text={["Are you sure you want to resolve / unresolve this report?"]}
				submitBtn="Confirm"
				closeBtn="Cancel"
				active={popups.reportToResolveId !== ''}
				onSubmit={() => onToggleResolveReport()}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #123126346233']}
				closeBtn="Close"
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<PageHeader heading="Reports" />
			
			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="CMSLAYOUT__table">
								<table>
									<thead>
										<tr>
											<th>
											</th>

											<th>
												<p>Report ID</p>
											</th>

											<th>
												<p>User ID</p>
											</th>

											<th>
												<p>Item ID</p>
											</th>

											<th>
												<p>Type</p>
											</th>

											<th>
												<p>Message</p>
											</th>

											<th>
												<p>Is resolved</p>
											</th>

											<th>
												<p>Created at</p>
											</th>

											<th>
												<p>Updated at</p>
											</th>

											<th>
												<p>Deleted at</p>
											</th>
										</tr>
									</thead>

									<tbody>
										{
											_.map(reports, (val1, i1) => {												
												let bg = '';

												switch (val1.isResolved) {
													case false: bg = 'yellow'; break;
													case true: bg = 'green'; break;
													default: bg = '';
												}

												return (
													<tr key={i1} className={ids.expandedRowId === i1.toString() ? 'expanded': ''}>
														<td>
															<div className="cell">
																<div className={'color bg-' + bg}></div>

																<button onClick={() => setIds(prevState => ({...initIds, 'expandedRowId': (prevState.expandedRowId === i1.toString() ? '' : i1.toString())}))} className="BUTTON size1">{ ids.expandedRowId === i1.toString() ? 'Collapse' : 'Expand' }</button>
																<button onClick={() => setPopups({...initPopups, 'reportToResolveId': val1._id})} className="BUTTON size1">{val1.isResolved ? 'Unresolve' : 'Resolve'}</button>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1._id }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.userId }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.itemId }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.type }</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<p>{ val1.text?.en }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.isResolved ? 'Yes' : 'No' }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.createdAt && moment(val1.createdAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.updatedAt && moment(val1.updatedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.deletedAt && moment(val1.deletedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{
						reportsTotalPages > 1 &&
						<div className="row">
							<div className="col-12">
								<Pagination
									currentPage={reportsPage}
									totalPages={reportsTotalPages}
									prevText="Prev"
									nextText="Next"
									onChangePage={(val: number) => onGetReports(val)}
								/>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default CmsReports;