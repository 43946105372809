import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Loader from './../../components/loader/loader';
// import PageHeader from './../../components/page-header/page-header';
import Popup from './../../components/popup/popup';
import Guide from './../../components/guide/guide';
import ProfileItem from './../../components/profile-item/profile-item';

// Import styles
import './other.scss';

interface props {
	'pageId'?: string
}

const Other = (props: props) => {
	const navigate = useNavigate();

	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	// const [isLoading, setIsLoading] = useState<boolean>(false);
	const [text, setText] = useState<{[key: string]: any}>({});

	const [randomizedProfiles, setRandomizedProfiles] = useState<Array<any>>([]);

	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		if (!props.pageId) { return; }

		api.get('/get-page/' + props.pageId).then(async (res) => {
			setText(res.data.text[lang]);

			await onGetRandomizedProfiles();
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	const onGetRandomizedProfiles = async () => {
		await new Promise((resolve, reject) => {
			// Get profiles by user's filter settings
			api.get('/get-randomized-profiles/').then((res) => {
				setRandomizedProfiles(res.data.entries);
				resolve(true);
			}).catch((err) => {
				setNotifications(err.response?.data?.messages);
				resolve(false);
			});
		});
	};

	return (
		<div className="OTHER">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
			
			<Loader active={false} fixed={true} />

			<Guide
				title={text.guidePopup?.title}
				text={text.guidePopup?.text1}
				nextButton={text.guidePopup?.button1}
			/>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #56234233321']}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>
			
			{/*<PageHeader heading={text.pageTitle} />*/}

			<div className="SECTION">
				<div className="grid">
					<div className="row">
						{
							_.map(text.otherItems, (val1, i1) => {
								return(
									<div key={i1} className="col-6 col-sm-4">
										<div className={'OTHERITEM full-height' + 
											(val1.isRestricted && !auth.isAuthenticated ? ' disabled' : '') + 
											(
												(
													(val1.hasMessages && auth.newMessages > 0) ||
													(val1.hasLogs && auth.newLogs > 0)
												) ? ' diff1' : '')
										}>
											<div className="OTHERITEM__content">
												<div className="OTHERITEM__body">
													{
														(!val1.isRestricted || auth.isAuthenticated) && 
														val1?.link &&
														<Link to={val1.hasMainProfileId ? slugByPageId(val1?.link, lang, routes).replace(':id', auth.mainProfile?.id) : slugByPageId(val1?.link, lang, routes)} className="OTHERITEM__link LINK"></Link>
													}

													{
														(!val1.isRestricted || auth.isAuthenticated) && 
														val1?.url &&
														<a href={val1?.url} target="_blank" rel="noreferrer" className="OTHERITEM__link LINK"> </a>
													}

													<div className="OTHERITEM__icon">
														<div className={val1?.icon}></div>
													</div>

													{
														val1?.title &&
														<div className="OTHERITEM__text">
															<div className="SECTION__title font-md">
																{ val1?.title } 

																{ 
																	val1.hasMessages && 
																	auth.newMessages > 0 && 
																	<> ({ auth.newMessages })</>
																}

																{
																	val1.hasLogs &&
																	auth.newLogs > 0 &&
																	<> ({ auth.newLogs })</>
																}
															</div>

															{
																val1?.subtitle &&
																<p>{ val1?.subtitle }</p>
															}
														</div>
													}
												</div>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>

			{
				randomizedProfiles?.length > 0 &&
				<div className="SECTION has-top-border OTHER__slider overflow-hidden">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								{
									text.randomizedProfiles?.heading &&
									<div className="SLIDER__arrowssmall">
										<div className="SECTION__title font-lg">{ text.randomizedProfiles?.heading }</div>

										<div className="SLIDER__arrowssmall-arrows">
											<div className="SLIDER__arrowssmall-item prev">
												<span className={generalText.other?.sliderArrowsSmall}></span>
											</div>

											<div className="SLIDER__arrowssmall-item next">
												<span className={generalText.other?.sliderArrowsSmall}></span>
											</div>
										</div>
									</div>
								}

								<Swiper
									speed={300}
									className="randomized-profiles-slider"
									modules={[Navigation]}
									slidesPerView={4.5}
									centeredSlides={false}
									loop={false}
									spaceBetween={10}
									autoplay={false}
									navigation={{
										'prevEl': '.OTHER__slider .SLIDER__arrowssmall-item.prev',
										'nextEl': '.OTHER__slider .SLIDER__arrowssmall-item.next',
									}}
									breakpoints={{
										768: {
											'slidesPerView': 8,
											'spaceBetween': 20
										}
									}}
								>
									{
										_.map(randomizedProfiles, (val1, i1) => {
											return (
												<SwiperSlide key={i1}>
													<div className="SECTION__item">
														<ProfileItem
															layout="xs"
															isVisible={true}
															userId={val1.userId}
															profileId={val1._id}
															slug={val1.slug}
															images={val1.images}
															raisedAt={val1.raisedAt}
															activeAt={val1.activeAt}
															setNotifications={(val: {[key: string]: any}) => setNotifications(val)}
														/>
													</div>
												</SwiperSlide>
											)
										})
									}
								</Swiper>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
}

export default Other;