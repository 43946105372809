import React from 'react';
import { useSelector } from 'react-redux';

import './resolution.scss';

interface props {}

const Resolution = (props: props) => {
	const generalText = useSelector((state: any) => state.generalText);

	if (!generalText?.resolution?.text1) { return null; }
	
	return (
		<div className="RESOLUTION">
			<div className="RESOLUTION__inner">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<p className="RESOLUTION__text">{ generalText?.resolution?.text1 }</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Resolution;