// Import types
import { SET_GENERAL, STATE_RESET } from './types';

export const setGeneral = (data: {[key: string]: any}) => {
	return {
		'type': SET_GENERAL,
		'showFilterPopup': data.showFilterPopup,
		'showNewPostPopup': data.showNewPostPopup
	};
}

export const resetGeneral = () => {
	return {
		'type': STATE_RESET
	};
}