import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { validateFields } from './../../other/helpers/validate-fields';

// Import components
import Input from './../input/input';

interface props {
	'pageId': string,
	'notifications': any,
	'showRemindPasswordPopup': any,
	'isLoading': any
};

const RemindPassword = (props: props) => {
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initUser: {[key: string]: any} = {
		'emailForPassword': '',
	}

	const [user, setUser] = useState<{[key: string]: any}>(initUser);
	const [errors, setErrors] = useState<{[key: string]: any}>({});	

	const onRemindPassword = (e: any) => {
		e.preventDefault();

		props.isLoading(true);

		// Validate fields
		let stateClone: any = _.cloneDeep(user);

		// Validation
		let fieldErrors = validateFields(stateClone, ['user']);

		if (!_.isEmpty(fieldErrors)) {
			props.isLoading(false);
			setErrors(fieldErrors);

			return;
		}

		let userData: any = {
			'email': stateClone.emailForPassword
		}
		
		api.post('/remind-password', userData).then((res) => {
			setUser(initUser);
			props.isLoading(false);
			props.showRemindPasswordPopup(false);
			props.notifications(res.data?.messages);


		}).catch((err) => {
			setUser(initUser);
			props.isLoading(false);
			props.showRemindPasswordPopup(false);
			props.notifications(err.response?.data?.messages);
		});
	}

	return (
		<form onSubmit={(e: any) => onRemindPassword(e)}>
			<div className="SECTION no-bottom-padding">
				<div className="grid">
					<div className="row">
						{
							generalText.remindPasswordPopup?.text1 &&
							<div className="col-12" dangerouslySetInnerHTML={{ __html: generalText.remindPasswordPopup?.text1 }}></div>
						}

						<div className="col-12">
							<div className="FIELD">
								<Input
									value={user.emailForPassword}
									label={generalText.remindPasswordPopup?.input1?.label}
									placeholder={generalText.remindPasswordPopup?.input1?.placeholder}
									type="text"
									errors={errors.emailForPassword}
									onChange={(val: string) => setUser(prevState => ({...prevState, 'emailForPassword': val}))}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="SECTION no-bottom-padding">
				<div className="grid">
					<div className="row">
						{
							generalText.generalPopup?.button1?.text &&
							<div className="col-4">
								<button type="button" onClick={() => props.showRemindPasswordPopup(false)} className="BUTTON diff1">{ generalText.generalPopup?.button1?.text }</button>
							</div>
						}

						{
							generalText.remindPasswordPopup?.button1?.text &&
							<div className="col-8">
								<button type="submit" className="BUTTON">{ generalText.remindPasswordPopup?.button1?.text }</button>
							</div>
						}
					</div>
				</div>
			</div>
		</form>
	);
}

export default RemindPassword;