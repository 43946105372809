import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Controller } from 'react-scrollmagic';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Loader from './../../components/loader/loader';
import Popup from './../../components/popup/popup';
import PageHeader from './../../components/page-header/page-header';
import Guide from './../../components/guide/guide';

// Import styles
import './logs.scss';

interface props {
	'pageId': string
}

const Logs = (props: props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [text, setText] = useState<{[key: string]: any}>({});
	const [logs, setLogs] = useState<Array<any>>([]);
	const [logsPage, setLogsPage] = useState<number>(1);
	const [logsTotal, setLogsTotal] = useState<number>(0);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
			onGetLogs(1);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	const onGetLogs = (page: any) => {
		setIsLoading(true);
		
		if (page === 1) {
			setLogs([]);
			setLogsPage(1);
			setLogsTotal(0);
		}

		api.get('/get-user-logs/' + page).then((res) => {
			let sortedEntries: any = [...logs, ...res.data.entries];

			sortedEntries = _.sortBy(sortedEntries, function(val1: any) {
				if (val1.updatedAt) {
					return new Date(val1.updatedAt);
				} else {
					return new Date(val1.createdAt);
				}
			}).reverse();

			setIsLoading(false);

			setLogs(sortedEntries);
			setLogsPage(page + 1);

			// Get count of total posts and first post's date from DB
			if (page === 1) {
				setLogsTotal(res.data?.total || 0);
			}

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	};

	if (_.isEmpty(text)) { return null; }

	return (
		<div className="LOGS full-height">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
			
			<Loader active={isLoading} fixed={true} />

			<Guide
				title={text.guidePopup?.title}
				text={text.guidePopup?.text1}
				nextButton={text.guidePopup?.button1}
			/>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				closeBtn={generalText?.generalPopup?.button1?.text}
				text={notifications?.text || ['Unhandled error - #363463564355']}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				{

					logs?.length > 0 &&
					<PageHeader heading={text.pageTitle} />
				}
				
				{
					logs?.length > 0 &&
					<div className="SECTION has-top-border">
						<div className="grid">
							<div className="row">
								<div className="col-12 col-sm-8 push-sm-2 col-md-8 push-md-3 col-md-6">
									<div className="row">
										{
											_.map(logs, (val1, i1) => {
												return (
													<div key={i1} className="col-12">
														<div className="SECTION__item">
															<div className="LOGSITEM">
																<div className="LOGSITEM__content">
																	{
																		val1.receiverUserId === auth.id &&
																		val1.document === 'favorite' &&
																		<p>
																			<strong>{ val1.senderProfile?.name || 'Hidden profile' } </strong> 
																			{
																				val1.senderProfile?.slug &&
																				<em>(@{ val1.senderProfile?.slug })</em>
																			} favorited <strong>{ val1.receiverProfile?.name }</strong>
																		</p>
																	}

																	{
																		val1.receiverUserId === auth.id &&
																		val1.document === 'comment' &&
																		<p>
																			<strong>{ val1.senderProfile?.name || 'Hidden profile' } </strong> 
																			{
																				val1.senderProfile?.slug &&
																				<em>(@{ val1.senderProfile?.slug })</em>
																			} commented on <strong>{ val1.receiverProfile?.name }'s</strong> post:<br/>
																			<em>"{ val1.post?.text?.[lang]?.text.replace(/(<([^>]+)>)/gi, '').replace(/[\r\n]{3,}/g, '\n\n').substring(0, 100) + ' ...' }"</em>
																		</p>
																	}

																	{
																		val1.receiverUserId === auth.id &&
																		val1.document === 'like' &&
																		<p>
																			<strong>{ val1.senderProfile?.name || 'Hidden profile' } </strong> 
																			{
																				val1.senderProfile?.slug &&
																				<em>(@{ val1.senderProfile?.slug })</em>
																			} liked <strong>{ val1.receiverProfile?.name }'s</strong> post:<br/>
																			<em>"{ val1.post?.text?.[lang]?.text.replace(/(<([^>]+)>)/gi, '').replace(/[\r\n]{3,}/g, '\n\n').substring(0, 100) + ' ...' }"</em>
																		</p>
																	}

																	{
																		(val1.updatedAt || val1.createdAt) &&
																		<p><em>{ moment(val1.updatedAt || val1.createdAt).fromNow().charAt(0).toUpperCase() + moment(val1.updatedAt || val1.createdAt).fromNow().slice(1) }</em></p>
																	}
																</div>
															</div>
														</div>
													</div>
												)
											})
										}

										{
											logs?.length > 0 && 
											(logsTotal > logs.length) && 
											text.logs?.footer?.button1?.text &&
											<div className="col-6 push-3">
												<div className="SECTION__item">
													<button onClick={() => onGetLogs(logsPage)} className="BUTTON">{ text.logs?.footer?.button1?.text }</button>		
												</div>
											</div>
										}
										
										{
											(logsTotal <= logs.length) && 
											text.logs?.footer?.button1?.disabledText &&
											<div className="col-6 push-3">
												<div className="SECTION__item">
													<button className="BUTTON diff1" disabled={true}>{ text.logs?.footer?.button1?.disabledText }</button>
												</div>
											</div>
										}
									</div>

								</div>
							</div>
						</div>
					</div>
				}
		
				{
					(
						!isLoading && 
						logs?.length <= 0
					) &&
					<div className="SECTION text-center full-height">
						<div className="grid full-height">
							<div className="row full-height">
								<div className="col-12 full-height">
									<div className="SECTION__inner full-height no-padding">
										<div className="SECTION__inner-scroll align-content-vertical">
											<div className="SECTION__inner">
												{
													text.logs?.noResults?.icon &&
													<div className="SECTION__icon">
														<div className={text.logs?.noResults?.icon}></div>
													</div>
												}
												
												{
													text.logs?.noResults?.title &&
													<div className="SECTION__title">{ text.logs?.noResults?.title }</div>
												}
												
												{
													text.logs?.noResults?.subtitle &&
													<div className="SECTION__subtitle">{ text.logs?.noResults?.subtitle }</div>
												}
												
												{
													text.logs?.noResults?.text &&
													<div dangerouslySetInnerHTML={{ __html: text.logs?.noResults?.text }}></div>
												}

												{
													text.logs?.noResults?.buttons?.length > 0 &&
													<div className="row">
														{
															_.map(text.logs?.noResults?.buttons, (val1, i1) => {
																return (
																	<div key={i1} className={val1.gridClasses}>	
																		<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.link ? ' ' + val1.link : '')}>{ val1.text }</Link>
																	</div>
																)
															})
														}
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</Controller>
		</div>
	);
}

export default Logs;