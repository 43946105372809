import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import styles
import './select.scss';

interface props {
	'value': string | Array<any>,
	'name'?: string,
	'options': Array<any>,
	'dbOptions'?: string,
	'placeholder'?: string,
	'label'?: string,
	'labelHelp'?: any,
	'required'?: boolean,
	'strict'?: boolean,
	'icon'?: string,
	'nullable'?: boolean,
	'tag'?: boolean,
	'errors'?: any,
	'onSelect'?: any,
	'onChange': any,
	'setNotifications'?: any
};

const Select = (props: props) => {
	const [optionsToggled, setOptionsToggled] = useState<boolean>(false);
	const [options, setOptions] = useState<Array<any>>([]);
	const [optionsCopy, setOptionsCopy] = useState<Array<any>>([]);
	const [value, setValue] = useState<any>('');
	const [tags, setTags] = useState<any>([]);

	const onDataUpdate = useCallback((value: string) => {
		api.get('/get-data/' + props.dbOptions + '/' + (value ? value : '*')).then((res) => {
			setOptions(res.data || []);
			setOptionsCopy(res.data || []);
		});
	}, [props.dbOptions]);

	useEffect(() => {
		setOptions(props.dbOptions ? [] : props.options);
		setOptionsCopy(props.dbOptions ? [] : props.options);
		setValue(props.tag ? '' : props.value);
		setTags(props.tag ? props.value : []);

		// If options are set from db
		if (props.dbOptions) {
			onDataUpdate('*');
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// Update fields after data is populated from the db
		setValue(props.tag ? '' : props.value);
		setTags(props.tag ? props.value : []);

	}, [props.value, props.tag]);

	const onToggleOptions = (val: boolean) => {
		// If options are already toggled, do nothing
		if (optionsToggled === val) { return; }

		if (!props.strict && props.nullable) {
			props.onChange(value);
		}

		if (props.strict && !value && props.nullable) {
			setValue('');
			// props.onChange('');
		}
		
		// setTimeout(() => {
			// setval(props.tag ? '' : props.val);
			setOptionsToggled(val);
			setOptions(!value ? props.options : options);

			// If options are set from db, reset options
			if (!value && props.dbOptions) {
				onDataUpdate('*');
			}
		// }, 300);
	}

	let timeout: any = useRef(null);

	const onInputChange = (e: any) => {
		// If updating options from database
		if (props.dbOptions) {

			setValue(e.target.value);

			if (timeout.current) {
				clearTimeout(timeout.current);
			}

			// Get data when user stopped typing
			timeout.current = setTimeout(() => {
				onDataUpdate(e.target.value);
			}, 500);

		// If updating options on a client
		} else {
			let optionsArr: any = [];

			// Filter down options
			_.forEach(props.options, (val1, i1) => {
				if (e.target.value !== '' && val1.value.toLowerCase().includes(e.target.value.toLowerCase())) {
					optionsArr.push(val1);
				}
			});

			// If everything is removed, show loaded options
			if (e.target.value === '' && _.isEmpty(optionsArr)) {
				optionsArr = optionsCopy;
			}

			setValue(e.target.value);
			setOptions(optionsArr);
		}
	}

	const onSelectClick = (value: string) => {
		// Generate tags array
		const tagsArray: Array<any> = _.cloneDeep(tags);

		if (props.tag) {
			// Check if selected tags are unique and not empty
			if (!tagsArray.includes(value)) {
				if (value !== '') {
					tagsArray.push(value);
				}
			
				// Send tags via props
				props.onChange(tagsArray);

				// Turn off options window, set value and reset options
				if (value === '') {
					setOptions(props.options)
				}

				setValue([]);
				setOptionsToggled(false);

				// If options are set from db, reset options
				if (props.dbOptions) {
					onDataUpdate('*');
				}
			}
		}

		// If this is not a tag selection and strict selection and value cannot be found in the options
		if (!props.tag) {
			let val: any = _.cloneDeep(value);

			let selectedOption: {[key: string]: any} = _.find(optionsCopy, ['value', val]);

			if (props.strict && !selectedOption) {
				// Reset value
				val = '';
			}

			// If options have text besides the value
			if (props.strict && optionsCopy[0].text) {
				let newVal: any = {
					'value': val,
					'text': selectedOption?.text || ''
				}

				props.onChange(newVal);
			} else {
				// Pass value to props
				props.onChange(val);
			}

			// Turn off options window, set value and reset options
			setValue(val);
			setOptionsToggled(false);

			if (val === '') {
				setOptions(props.options);
			}

			// If options are set from db, reset options
			if (props.dbOptions && val === '') {
				onDataUpdate('*');
			}
		}
	}

	const onTagRemove = (index: string) => {
		let newTagsArr: any = _.cloneDeep(tags);
		newTagsArr.splice(index, 1);

		// Turn off options window, set value and reset options
		setValue([]);
		setOptionsToggled(false);
		setOptions(props.options);

		// If options are set from db, reset options
		if (props.dbOptions) {
			onDataUpdate('*');
		}

		// Pass tags array to props
		props.onChange(newTagsArr);
	}

	// If options have option key show option instead of value
	let shownValue: any = value;

	if (!_.isEmpty(options) && value !== '') {
		const optionIndex = _.findIndex(options, ['value', value]);
		
		if (options[optionIndex] && options[optionIndex].option) {
			shownValue = options[optionIndex].option;
		}
	}

	return (
		<div className={'SELECT' + (optionsToggled ? ' active' : '') + (props.errors ? ' error' : '')}>
			{
				props.label &&
				<div className="SELECT__label">
					<label htmlFor={props.name}>
						{ props.label }
						{
							props.labelHelp !== undefined &&
							<span onClick={() => props.setNotifications(props.labelHelp)} className="icon-interrogation-regular"></span>
						}
					</label>
				</div>
			}

			<div className={'SELECT__overlay' + (optionsToggled ? ' active' : '')} onClick={() => onToggleOptions(false)}></div>
			
			<div className={'SELECT__input' + (props.icon ? ' has-icon' : '')}>
				{
					props.icon &&
					<div className="SELECT__input-icon">
						<span className={props.icon}></span>
					</div>
				}

				<input
					type="text"
					name={props.name}
					value={shownValue}
					placeholder={props.placeholder}
					onChange={(e: any) => onInputChange(e)}
					onClick={() => onToggleOptions(true)}
					onFocus={() => onToggleOptions(true)}
					autoComplete="none"
				/>

				<div className="SELECT__input-arrow">
					<span className="icon-angle-left-regular"></span>
				</div>

				<div className={'SELECT__input-options' + (optionsToggled ? ' active' : '')}>
					{
						props.placeholder &&
						<div
							className={'SELECT__input-options-item' + (props.nullable ? ' nullable' : '')}
							onClick={() => onSelectClick('')}
						>
							{ props.placeholder }
						</div>
					}

					{
						(!props.strict && value) &&
						<div
							className="SELECT__input-options-item"
							onClick={() => onSelectClick(value)}
						>
							{ value }
						</div>
					}

					{
						_.map(options, (val1, i1) => {
							return (
								<div
									key={i1}
									className="SELECT__input-options-item"
									onClick={() => onSelectClick(val1['value'])}
								>
									{ val1['option'] ? val1['option'] : val1['value'] }
								</div>
							)
						})
					}
				</div>
			</div>

			{
				props.tag && !_.isEmpty(tags) &&
				<div className="SELECT__tags">
					{
						_.map(tags, (val1, i1) => {
							return (
								<span key={i1} className="SELECT__tags-item">
									{ val1 }

									<span className="SELECT__tags-item-icon">
										<span className="icon-plus-regular" onClick={() => onTagRemove(i1)}></span>
									</span>
								</span>
							)
						})
					}
				</div>
			}

			{
				props.errors &&
				<p className="error">
					{ props.errors }
				</p>
			}
		</div>
	);
}

export default Select;