import React from 'react';

// Import styles
import './textarea.scss';

interface props {
	'value': string,
	'name'?: string,
	'placeholder': string,
	'label'?: string,
	'labelHelp'?: any,
	'disabled'?: boolean,
	'minCharLength'?: number,
	'maxCharLength'?: number,
	'errors'?: any,
	'onChange': any,
	'setNotifications'?: any
};

const Textarea = (props: props) => {
	return (
		<div className={'TEXTAREA' + (props.errors ? ' error' : '')}>
			{
				props.label &&
				<div className="TEXTAREA__label">
					<label htmlFor={props.name}>
						{ props.label }
						
						{
							props.labelHelp !== undefined &&
							<span onClick={() => props.setNotifications(props.labelHelp)} className="icon-interrogation-regular"></span>
						}
					</label>
				</div>
			}

			<div className="TEXTAREA__input">
				<textarea
					name={props.name}
					placeholder={props.placeholder}
					onChange={(e: any) => props.onChange(e.target.value)}
					value={props.value}
					disabled={props.disabled}
				></textarea>
			</div>

			{
				props.minCharLength &&
				props.maxCharLength &&
				(props.maxCharLength - props.value?.length) < (props.maxCharLength / 2) &&
				<div className={'TEXTAREA__charcount' + (
					(props.value?.length < props.minCharLength) || (props.value?.length > props.maxCharLength) ? ' red' : ' green'
				)}>
					{ props.maxCharLength - props.value?.length } characters left
				</div>
			}

			{
				props.errors &&
				<p className="error">
					{ props.errors }
				</p>
			}
		</div>
	);
}

export default Textarea;