import { STATE_RESET, SET_GENERAL } from './../actions/types';

const initialState: {[key: string]: any} = {
	'showFilterPopup': false,
	'showNewPostPopup': false
};

const updatedState = (state: {[key: string]: any} = initialState, action: {[key: string]: any} = {}) => {
	let data: {[key: string]: any} = {
		'showFilterPopup': action['showFilterPopup'],
		'showNewPostPopup': action['showNewPostPopup']
	};

	switch(action['type']) {

		case STATE_RESET: return initialState;

		case SET_GENERAL: return data;
		
		default: return state;
	}
}

export default updatedState;