import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// Import middlewares
import api from './../../../other/middlewares/api';

// Import components
import PageHeader from './../../../components/page-header/page-header';
import Loader from './../../../components/loader/loader';
import Pagination from './../../../components/pagination/pagination';
import Popup from './../../../components/popup/popup';

interface props {};

const CmsComments = (props: props) => {
	const lang = useSelector((state: {[key: string]: any}) => state.lang);

	const initIds: {[key: string]: any} = {
		'expandedRowId': '',
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [comments, setComments] = useState<Array<any>>([]);
	const [commentsPage, setCommentsPage] = useState<number>(1);
	const [commentsTotalPages, setCommentsTotalPages] = useState<number>(0);

	const [ids, setIds] = useState<{[key: string]: any}>(initIds);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	const onGetComments = useCallback((page: any) => {
		setIsLoading(true);

		if (page === 1) {
			setComments([]);
			setCommentsPage(1);
			setCommentsTotalPages(1);
		}

		api.get('/admin-get-trashed-comments/' + page).then((res) => {
			setCommentsPage(page);
			setComments(res.data.entries);

			if (page === 1 && res.data?.total) {
				setCommentsTotalPages(Math.round(res.data.total / res.data.entries.length));
			}

			setIsLoading(false);

		}).catch((err) => {

			setNotifications(err.response?.data?.messages);
			setIsLoading(false);

		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onGetComments(1);
	}, [onGetComments]);

	return (
		<div className="CMSTRASHEDCOMMENTS">
			<Loader active={isLoading} fixed={true} />

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #342635746555']}
				closeBtn="Close"
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<PageHeader heading="Comments" />

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="CMSLAYOUT__table">
								<table>
									<thead>
										<tr>
											<th>
											</th>

											<th>
												<p>Comment ID</p>
											</th>

											<th>
												<p>Post ID</p>
											</th>

											<th>
												<p>User IDs</p>
											</th>

											<th>
												<p>Profile IDs</p>
											</th>

											<th>
												<p>Text</p>
											</th>

											<th>
												<p>Created at</p>
											</th>

											<th>
												<p>Updated at</p>
											</th>

											<th>
												<p>Deleted at</p>
											</th>
										</tr>
									</thead>

									<tbody>
										{
											_.map(comments, (val1, i1) => {
												return (
													<tr key={i1} className={ids.expandedRowId === i1.toString() ? 'expanded': ''}>
														<td>
															<div className="cell">
																<button onClick={() => setIds(prevState => ({...initIds, 'expandedRowId': (prevState.expandedRowId === i1.toString() ? '' : i1.toString())}))} className="BUTTON size1">{ ids.expandedRowId === i1.toString() ? 'Collapse' : 'Expand' }</button>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1._id }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.postId }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>Sender: { val1.senderUserId }</p>
																<p>Receiver: { val1.receiverUserId }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>Sender: { val1.senderProfileId }</p>
																<p>Receiver: { val1.receiverProfileId }</p>
															</div>
														</td>
														
														<td className="allow-wrap pre-line">
															<div className="cell">
																<p>{ val1.text?.[lang]?.text }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.createdAt && moment(val1.createdAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.updatedAt && moment(val1.updatedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.deletedAt && moment(val1.deletedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{
						commentsTotalPages > 1 &&
						<div className="row">
							<div className="col-12">
								<Pagination
									currentPage={commentsPage}
									totalPages={commentsTotalPages}
									prevText="Prev"
									nextText="Next"
									onChangePage={(val: number) => onGetComments(val)}
								/>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default CmsComments;