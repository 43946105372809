// Import types
import { SET_FILTER, STATE_RESET } from './types';

export const setFilter = (data: {[key: string]: any}) => {
	return {
		'type': SET_FILTER,
		'country': data.country,
		'city': data.city,
		'category': data.category,
		'dataType': data.type,
		'interest': data.interest,
		'searchPhrase': data.searchPhrase,
		'postKind': data.postKind,
	};
}

export const resetFilter = () => {
	return {
		'type': STATE_RESET
	};
}