// Import types
import { SET_GENERAL_TEXT, STATE_RESET } from './../actions/types';

export const setGeneralText = (data: {[key: string]: any}) => {
	return {
		'type': SET_GENERAL_TEXT,
		...data
	};
}

export const resetGeneralText = () => {
	return {
		'type': STATE_RESET
	};
}