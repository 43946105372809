import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// Import styles
import './page-header.scss';

interface props {
	'h1'?: boolean,
	'heading': string
};


const PageHeader = (props: props) => {
	const navigate = useNavigate();
	const location = useLocation();

	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	return (
		<div className="SECTION padding-wrap-sm PAGEHEADER">
			<div className="grid">
				<div className="row">
					<div className="col-12">
						<div className="SECTION__item">
							<div className="PAGEHEADER__inner">
								{
									location.key !== "default" &&
									<span onClick={() => navigate(-1)} className="PAGEHEADER__return">
										<div className={generalText.other?.sliderArrowsSmall}></div>
									</span>
								}

								{/*{
									!props.history.location.key &&
									<NavLink to="/" className="PAGEHEADER__return">
										<div className="icon-angle-left-regular"></div>
									</NavLink>
								}*/}

								{
									props.h1 && props.heading &&
									<h1 className="PAGEHEADER__text">
										{ props.heading }
									</h1>
								}

								{
									!props.h1 && props.heading &&
									<span className="PAGEHEADER__text">
										{ props.heading }
									</span>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PageHeader;
