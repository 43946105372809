import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// Import middlewares
import api from './../../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../../other/helpers/route-finder';

// Import components
import PageHeader from './../../../components/page-header/page-header';
import Loader from './../../../components/loader/loader';
import Pagination from './../../../components/pagination/pagination';
import Popup from './../../../components/popup/popup';

interface props {};

const CmsTrashedPosts = (props: props) => {
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const initIds: {[key: string]: any} = {
		'expandedRowId': '',
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [posts, setPosts] = useState<Array<any>>([]);
	const [postsPage, setPostsPage] = useState<number>(1);
	const [postsTotalPages, setPostsTotalPages] = useState<number>(0);
	
	const [ids, setIds] = useState<{[key: string]: any}>(initIds);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	const onGetPosts = useCallback((page: any) => {
		setIsLoading(true);

		if (page === 1) {
			setPosts([]);
			setPostsPage(1);
			setPostsTotalPages(1);
		}

		api.get('/admin-get-trashed-posts/' + page).then((res) => {
			setIsLoading(false);
			setPostsPage(page);
			setPosts(res.data.entries);

			if (page === 1 && res.data?.total) {
				setPostsTotalPages(Math.round(res.data.total / res.data.entries.length));
			}

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onGetPosts(1);
	}, [onGetPosts]);

	return (
		<div className="CMSTRASHEDPOSTS">
			<Loader active={isLoading} fixed={true} />

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #7643453452']}
				closeBtn="Close"
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<PageHeader heading="Posts" />

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="CMSLAYOUT__table">
								<table>
									<thead>
										<tr>
											<th>
											</th>

											<th>
												<p>Post ID</p>
											</th>

											<th>
												<p>User</p>
											</th>

											<th>
												<p>Profile</p>
											</th>

											<th>
												<p>Kind</p>
											</th>

											<th>
												<p>Text</p>
											</th>

											<th>
												<p>Country</p>
											</th>

											<th>
												<p>Type</p>
											</th>

											<th>
												<p>Category</p>
											</th>

											<th>
												<p>Status</p>
											</th>

											<th>
												<p>Status message</p>
											</th>

											<th>
												<p>Created at</p>
											</th>

											<th>
												<p>Updated at</p>
											</th>

											<th>
												<p>Deleted at</p>
											</th>
										</tr>
									</thead>

									<tbody>
										{
											_.map(posts, (val1, i1) => {
												let bg: string = '';

												switch (val1.status) {
													case 0: bg = 'yellow'; break;
													case 1: bg = 'green'; break;
													case 2: bg = 'red'; break;
													default: bg = '';
												}

												return (
													<tr key={i1} className={ids.expandedRowId === i1.toString() ? 'expanded': ''}>
														<td>
															<div className="cell">
																<div className={'color bg-' + bg}></div>
																<button onClick={() => setIds(prevState => ({...initIds, 'expandedRowId': (prevState.expandedRowId === i1.toString() ? '' : i1.toString())}))} className="BUTTON size1">{ ids.expandedRowId === i1.toString() ? 'Collapse' : 'Expand' }</button>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1._id }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>
																	<strong>User ID:</strong> { val1.userId }<br />
																	<strong>User name:</strong> { val1.user?.firstName} {val1.user?.lastName }<br />
																	<strong>User email:</strong> { val1.user?.email }
																</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>
																	<strong>Profile ID:</strong> <Link to={slugByPageId('profile', lang, routes).replace(':slug', val1.profileSlug)}>{ val1.profileId }</Link><br />
																	<strong>Profile name:</strong> { val1.profile?.name }
																</p>
															</div>
														</td>

														<td className="allow-wrap pre-line">
															<div className="cell">
																<p>{ val1.kind }</p>
															</div>
														</td>

														<td className="allow-wrap pre-line">
															<div className="cell">
																<p>{ val1.text?.text }</p>
															</div>
														</td>
														
														<td>
															<div className="cell">
																<p>{ val1.country }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.type }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.category }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.status }</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<p>{ val1.statusMessage }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.createdAt && moment(val1.createdAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.updatedAt && moment(val1.updatedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.deletedAt && moment(val1.deletedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{
						postsTotalPages > 1 &&
						<div className="row">
							<div className="col-12">
								<Pagination
									currentPage={postsPage}
									totalPages={postsTotalPages}
									prevText="Prev"
									nextText="Next"
									onChangePage={(val: number) => onGetPosts(val)}
								/>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default CmsTrashedPosts;