import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import _ from 'lodash';

// Import middlewares
import api from './../other/middlewares/api';

// Import actions
import { setLang } from './../other/actions/lang';
import { setGeneralText } from './../other/actions/general-text';

// Import components
import Header from './../components/header/header';
import Navigation from './../components/navigation/navigation';
import Resolution from './../components/resolution/resolution';
import Footer from './../components/footer/footer';

import './cms-layout.scss';

interface props {
	'lang': string
};

function CmsLayout(props: props) {
	const dispatch = useDispatch();

	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const generalText = useSelector((state: any) => state.generalText);
	
	if (lang !== props.lang) {
		dispatch(setLang(props.lang));
	}

	useEffect(() => {
		api.get('/get-page/general').then((res) => {
			dispatch(setGeneralText({'generalText': res.data.text[props.lang]}))
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!auth.isAuthenticated) {
			document.body.classList.toggle('dark-theme', (generalText?.general?.isDarkThemeEnabled ? true : false));
		} else {
			document.body.classList.toggle('dark-theme', auth.isDarkThemeEnabled);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[auth.isDarkThemeEnabled, auth.isAuthenticated]);
	
	if (!generalText || _.isEmpty(generalText)) { return null; }

	return (
		<div className={'MAINLAYOUT CMSLAYOUT' + (auth.isAuthenticated ? ' diff1' : '')}>
			<Header />
			<Navigation />
			
			<div className="PAGE">
				<Outlet />
			</div>

			<Resolution />
			<Footer />
		</div>
	);
}

export default CmsLayout;