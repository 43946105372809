import React, { useState } from 'react';
import _ from 'lodash';
// import EXIF from 'exif-js';
import Dropzone from 'react-dropzone';
import { getBase64Strings } from 'exif-rotate-js';

// Import styles
import './upload.scss';

interface props {
	'name'?: string,
	'value': Array<any>,
	'formats': string,
	'maxSize': number,
	'maxFiles': number,
	'multiple': boolean,
	'label'?: string,
	'labelHelp'?: any,
	'placeholder': string,
	'icon': string,
	'errors'?: any,
	'onChange': any,
	'setNotifications'?: any
};

const Upload = (props: props) => {
	const [isDragEntered, setIsDragEntered] = useState<boolean>(false);

	const onChange = async (acceptedFiles: any, rejectedFiles: any) => {
		if (!_.isEmpty(acceptedFiles) && !acceptedFiles.type) {
			acceptedFiles[0]['preview'] = URL.createObjectURL(acceptedFiles[0]);
			acceptedFiles[0]['rotation'] = 0;

			props.onChange(acceptedFiles, rejectedFiles);
			
			return;
		}

		if (!_.isEmpty(rejectedFiles)) {
			props.onChange(acceptedFiles, rejectedFiles);

			return;
		}
		
		if (!_.isEmpty(acceptedFiles) && acceptedFiles.length > props.maxFiles) {
			acceptedFiles.length = props.maxFiles;
		}

		// Strip all files from exif and other data, rotate them correctly
		let acceptedFilesDataURL: any = await getBase64Strings(acceptedFiles, { maxSize: 1024 });

		let newAcceptedFiles: Array<any> = [];

		acceptedFilesDataURL.forEach((base64: any, index: number) => {
			let newFile: any;

			// Basically check if this is not IE as IE does not support 'new File'.
			try {
				// Create new stripped file
				newFile = dataURLtoFile(base64, acceptedFiles[index].name);
				
			} catch {
				// Use original uploaded file
				newFile = acceptedFiles[index];
			}

			Object.assign(newFile, {
				preview: URL.createObjectURL(newFile),
				rotation: 0
			});

			newAcceptedFiles.push(newFile);
		});


		props.onChange(newAcceptedFiles, rejectedFiles);
	}

	const dataURLtoFile = (dataUrl: any, filename: string) => {

		let arr = dataUrl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]), 
			n = bstr.length, 
			u8arr = new Uint8Array(n);
			
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		
		return new File([u8arr], filename, {type:mime});
	}

	const onRotate = (e: any, val: {[key: string]: any}, rotationDirection: boolean) => {
		e.stopPropagation();

		if (rotationDirection) {
			if (val.rotationDirection >= 270) { return; }
			val.rotation = parseInt(val.rotation) + 90;
			val.isRotationChanged = true;
		} else {
			if (val.rotationDirection <= 0) { return; }
			val.rotation = parseInt(val.rotation) - 90;
			val.isRotationChanged = true;
		}
		
		props.onChange([val], []);
	}

	const onRemoveImage = (e: any, index: number) => {
		e.stopPropagation();

		if (_.isEmpty(props.value)) { return; }

		const valueClone = _.clone(props.value);

		valueClone.splice(index, 1);

		props.onChange(valueClone);
	}

	const onDragEnter = () => {
		setIsDragEntered(true);
	}

	const onDragLeave = () => {
		setIsDragEntered(false);
	}

	const onDropAccepted = () => {
		setIsDragEntered(false);
	}

	let formats = props.formats.split(',').map(format => format.trim());
	let acceptedFormats: any = {};

	formats.forEach((format: any) => {
		acceptedFormats[format] = [];
	});

	return (
		<div className={'UPLOAD' + (props.errors ? ' error' : '')}>
			{
				props.label &&
				<div className="UPLOAD__label">
					<label htmlFor={props.name}>
						{ props.label }
						{
							props.labelHelp !== undefined &&
							<span onClick={() => props.setNotifications(props.labelHelp)} className="icon-interrogation-regular"></span>
						}
					</label>
				</div>
			}

			<div className={'UPLOAD__dropzone' + (isDragEntered ? ' accepted' : '')}>
				<Dropzone 
					onDrop={(acceptedFiles, rejectedFiles) => onChange(acceptedFiles, rejectedFiles)}
					accept={acceptedFormats}
					maxSize={props.maxSize}
					multiple={props.multiple}
					onDragEnter={() => onDragEnter()}
					onDragLeave={() => onDragLeave()}
					onDropAccepted={() => onDropAccepted()}
				>	

					{
						({getRootProps, getInputProps}) => (
						<div className="UPLOAD__dropzone-inner" {...getRootProps()}>
							<input {...getInputProps()} className="UPLOAD__input" />

							{
								_.map(props.value, (val1, i1) => {
									return(
										<div key={i1} className="UPLOAD__image">
											<div className={
												'UPLOAD__image-preview' + 
												(val1.rotation === 90 ? ' r90deg' : '') +
												(val1.rotation === 180 ? ' r180deg' : '') +
												(val1.rotation === 270 ? ' r270deg' : '')
											} style={{backgroundImage: 'url('+ val1.preview +')'}}></div>
											
											<div className="UPLOAD__image-remove" onClick={(e) => onRemoveImage(e, i1)}>
												<div className="icon-plus-regular"></div>
											</div>

											<div className="UPLOAD__image-rotation">
												<span onClick={(e) => onRotate(e, val1, false)} className={'LINK' + (val1.rotation <= 0 ? ' disabled' : '')}>Rotate left</span>
												<span onClick={(e) => onRotate(e, val1, true)} className={'LINK' + (val1.rotation >= 270 ? ' disabled' : '')}>Rotate right</span>
											</div>
											
											{
												!props.value[0].oldImage &&
												<div className="UPLOAD__image-name">{val1.name}</div>
											}
										</div>
									)
								})
							}

							{
								props.value.length === 0 &&
								<div className="UPLOAD__image">
									<div className="UPLOAD__image-preview">
										<div className={props.icon}></div>
									</div>

									{
										props.placeholder &&
										<div className="UPLOAD__image-name" dangerouslySetInnerHTML={{__html: props.placeholder}}></div>
									}
								</div>
							}
						</div>
						)
					}
				</Dropzone>
			</div>

			{
				props.errors &&
				<p className="error">
					{ props.errors }
				</p>
			}
		</div>
	);
}

export default Upload;