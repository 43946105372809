import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import _ from 'lodash';

// Import middlewares
import api from './../../../other/middlewares/api';

// Import helpers
import { validateFields } from './../../../other/helpers/validate-fields';

// Import components
import PageHeader from './../../../components/page-header/page-header';
import Loader from './../../../components/loader/loader';
import Input from './../../../components/input/input';
import Textarea from './../../../components/textarea/textarea';
import Pagination from './../../../components/pagination/pagination';
import Popup from './../../../components/popup/popup';

interface props {};

const CmsEmails = (props: props) => {
	const initAggregationQuery: string = JSON.stringify([{"$match":{"email":"aivarasfindupp@gmail.com","isEmailPromotionsAllowed": true,"isEmailAppUpdatesAllowed": true,"isEmailMessageNotificationsAllowed": true}},{"$lookup":{"from":"profiles","let":{"userId":"$_id"},"pipeline":[{"$match":{"$expr":{"$and":[{"$eq":["$userId","$$userId"]}]},"status":1}},{"$count":"total"}],"as":"profiles"}},{"$unwind":{"path":"$profiles","preserveNullAndEmptyArrays":true}},{"$project":{"_id":0,"email":1,"profiles":1}},{"$sort":{"createdAt":-1}}], null, 4)
	
	const initEmail: {[key: string]: any} = {
		'name': '',
		'aggregationQuery': initAggregationQuery,
		'title': '',
		'body': '',
		'showUnsubscribe': true
	}
	const initIds: {[key: string]: any} = {
		'expandedRowId': ''
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}
	
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [email, setEmail] = useState<{[key: string]: any}>(initEmail);
	const [receivers, setReceivers] = useState<Array<any>>([]);

	const [emails, setEmails] = useState<Array<any>>([]);
	const [emailsPage, setEmailsPage] = useState<number>(1);
	const [emailsTotalPages, setEmailsTotalPages] = useState<number>(0);
	
	const [ids, setIds] = useState<{[key: string]: any}>(initIds);
	const [errors, setErrors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	const onGetEmails = useCallback((page: any) => {
		setIsLoading(true);

		if (page === 1) {
			setEmails([]);
			setEmailsPage(1);
			setEmailsTotalPages(1);
		}

		api.get('/admin-get-emails/' + page).then((res) => {
			setIsLoading(false);
			setEmailsPage(page);
			setEmails(res.data.entries);

			if (page === 1 && res.data?.total) {
				setEmailsTotalPages(Math.ceil(res.data.total / res.data.entries.length));
			}

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onGetEmails(1);
	}, [onGetEmails]);

	const onSendEmails = (isActualSend: boolean) => {
		setIsLoading(true);

		let stateClone: any = _.cloneDeep(email);

		// Validation
		let fieldErrors = validateFields(stateClone, ['email']);

		if (!_.isEmpty(fieldErrors)) {
			setIsLoading(false);
			setErrors(fieldErrors);
			
			return;
		}

		stateClone['isPreview'] = !isActualSend;

		api.post('/admin-send-emails', stateClone).then((res1) => {

			if (!isActualSend) {
				setReceivers(res1.data);
			}

			if (isActualSend) {
				setEmail(initEmail);
				onGetEmails(1);
				setNotifications({
					'title': 'Success!',
					'text': ['Your message has been sent!']
				});
			}
			
			setIsLoading(false);
			
		}).catch((err) => {

			setNotifications(err.response?.data?.messages);
			setIsLoading(false);

		});
	}

	return (
		<div className="CMSEMAILS">
			<Loader active={isLoading} fixed={true} />

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #123126346233']}
				closeBtn="Close"
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<PageHeader heading="Emails" />

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="CMSLAYOUT__table">
								<table>
									<thead>
										<tr>
											<th>
											</th>

											<th>
												<p>Email ID</p>
											</th>

											<th>
												<p>Receivers</p>
											</th>

											<th>
												<p>Name</p>
											</th>

											<th>
												<p>Aggregation query</p>
											</th>

											<th>
												<p>Title</p>
											</th>

											<th>
												<p>Body</p>
											</th>

											<th>
												<p>Show unsubscribe</p>
											</th>

											<th>
												<p>Created at</p>
											</th>

											<th>
												<p>Updated at</p>
											</th>

											<th>
												<p>Deleted at</p>
											</th>
										</tr>
									</thead>

									<tbody>
										{
											_.map(emails, (val1, i1) => {												
												let bg = '';

												switch (val1.isResolved) {
													case false: bg = 'yellow'; break;
													case true: bg = 'green'; break;
													default: bg = '';
												}

												return (
													<tr key={i1} className={ids.expandedRowId === i1.toString() ? 'expanded': ''}>
														<td>
															<div className="cell">
																<div className={'color bg-' + bg}></div>

																<button onClick={() => setIds(prevState => ({...initIds, 'expandedRowId': (prevState.expandedRowId === i1.toString() ? '' : i1.toString())}))} className="BUTTON size1">{ ids.expandedRowId === i1.toString() ? 'Collapse' : 'Expand' }</button>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1._id }</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<p>{ JSON.stringify(val1.receivers) }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.name }</p>
															</div>
														</td>

														<td className="allow-wrap pre-line">
															<div className="cell">
																<p>{ JSON.stringify(val1.aggregationQuery, null, 4) }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.title }</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<p>{ val1.body }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.showUnsubscribe ? 'Yes' : 'No' }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.createdAt && moment(val1.createdAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.updatedAt && moment(val1.updatedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.deletedAt && moment(val1.deletedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{
						emailsTotalPages > 1 &&
						<div className="row">
							<div className="col-12">
								<Pagination
									currentPage={emailsPage}
									totalPages={emailsTotalPages}
									prevText="Prev"
									nextText="Next"
									onChangePage={(val: number) => onGetEmails(val)}
								/>
							</div>
						</div>
					}
				</div>
			</div>

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="SECTION__title font-lg text-center">Compose email</div>
						</div>

						<div className="col-12">
							<div className="FIELD diff1">
								<Input
									label="Name"
									type="text"
									placeholder="Enter name..."
									value={email.name}
									errors={errors.name}
									onChange={(val: string) => setEmail(prevState => ({...prevState, 'name': val}))}
								/>
							</div>
						</div>

						<div className="col-12">
							<div className="FIELD diff1">
								<Textarea
									label="Aggregation query"
									placeholder="Enter aggregation query in JSON format..."
									value={email.aggregationQuery}
									errors={errors.aggregationQuery}
									onChange={(val: string) => setEmail(prevState => ({...prevState, 'aggregationQuery': val}))}
								/>
							</div>
						</div>

						<div className="col-12">
							<div className="FIELD diff1">
								<Input
									label="Title"
									type="text"
									placeholder="Enter title..."
									value={email.title}
									errors={errors.title}
									onChange={(val: string) => setEmail(prevState => ({...prevState, 'title': val}))}
								/>
							</div>
						</div>

						<div className="col-12">
							<div className="FIELD diff1">
								<Textarea
									label="Body"
									placeholder="Enter body in HTML format..."
									value={email.body}
									errors={errors.body}
									onChange={(val: string) => setEmail(prevState => ({...prevState, 'body': val}))}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-6 col-sm-4 push-sm-2">
							<div className="BUTTON diff2" onClick={() => onSendEmails(false)}>
								Check preview
							</div>
						</div>

						<div className="col-6 col-sm-4">
							<div className="BUTTON" onClick={() => onSendEmails(true)}>
								Send emails
							</div>
						</div>
					</div>
				</div>
			</div>

			{
				(
					receivers?.length > 0 ||
					email.body
				) &&
				<div className="SECTION has-top-border">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="SECTION__title font-lg text-center">Preview</div>
							</div>

							{
								email.body &&
								<div className="col-12" dangerouslySetInnerHTML={{ __html : email.body }}></div>
							}

							{
								receivers?.length > 0 &&
								<div className="col-12">
									<div className="SECTION__item">
										<div className="SECTION__subtitle">Receivers:</div>

										<ul className="row">
											{
												_.map(receivers, (val1: any, i1: any) => {
													return (
														<li key={i1} className="col-6 col-sm-4 col-md-3">
															{ val1.email }
														</li>
													)
												})
											}
										</ul>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			}
		</div>
	);
}

export default CmsEmails;