import React from 'react';

// Import styles
import './radio.scss';

interface props {
	'value': string | number,
	'selectedValue': string | number,
	'name': string,
	'label': string,
	'errors'?: any,
	'onChange': any
};

const Radio = (props: props) => {
	return (
		<label className={'RADIO' + (props.errors ? ' error' : '')}>
			<span className="RADIO__item">
				<input 
					type="radio" 
					checked={props.value === props.selectedValue ? true : false}
					onChange={() => props.onChange(props.value)} 
					name={props.name}
				/>

				<span className="RADIO__item-box"></span>
				
				{
					props.label &&
					<span className="RADIO__item-label" dangerouslySetInnerHTML={{ __html: props.label }}></span>
				}
			</span>
		</label>
	);
}

export default Radio;
