import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Controller } from 'react-scrollmagic';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import configs
import { apiBasePath } from './../../other/configs/urls';

// Import components
import PageHeader from './../../components/page-header/page-header';
import ProgressiveImg from './../../components/progressive-img/progressive-img';
import Footer from './../../components/footer/footer';

// Import styles
import './text.scss';

interface props {
	'pageId': string
}

const Text = (props: props) => {
	const navigate = useNavigate();

	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const [text, setText] = useState<{[key: string]: any}>({});

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
		}).catch((err) => {			
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	if (_.isEmpty(text)) { return null; }

	let pageImage: string = '';

	if (text.images?.[0]?.path) {
		pageImage = apiBasePath + '/shared/uploads-blog/' + text.images[0].path;
	}

	return (
		<div className="TEXT">
			<Helmet
				titleTemplate={generalText?.siteTitle + ' | %s'}
				defaultTitle={generalText?.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				<PageHeader heading={text.pageTitle} h1={true} />

				<div className="SECTION">
					<div className="grid">
						<div className="row">
							<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
								{
									pageImage &&
									<span className="SECTION__image">
										<ProgressiveImg
											img={true}
											alt={text.images?.[0]?.alt}
											showLoader={true}
											shouldLoad={true}
											lowQualitySrc=""
											highQualitySrc={pageImage}
										/>
									</span>
								}
								
								<div className="TEXT__content" dangerouslySetInnerHTML={{ __html: text.text }}></div>
							</div>
						</div>
					</div>
				</div>
			</Controller>

			<Footer />
		</div>
	);
}

export default Text;