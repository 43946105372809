// Import types
import { SET_LANG, STATE_RESET } from './types';

export const setLang = (data: string) => {
	return {
		'type': SET_LANG,
		'lang': data
	};
}

export const resetRoutes = () => {
	return {
		'type': STATE_RESET
	};
}