// Import configs
import keys from './../configs/keys';

const messages = {
	// General
	'notEnoughCreditsError': {
		'title': 'Not enough credits',
		'text': ['It seems that you don\'t have enough credits to complete this action. Visit settings to find out how to increase them.']
	},
	'itemReportedMessage': {
		'title': 'Thank you!',
		'text': ['Thank you for reporting this and for making our community safer! Our team will take a look into this case.']
	},
	'profileIsNotSelected': {
		'title': 'Select main profile',
		'text': ['If you wish to complete this action, you need to select your main profile first.']
	},
	'creditsUpdateTimeError': {
		'title': 'Credits have already been added',
		'text': ['Your daily credits have recently been added. Please wait at least ' + keys.hoursToUpdateCredits + ' hours.']
	},
	'userNotAuthorizedError': {
		'title': 'Please sign in',
		'text': ['You have to sign in to complete this action.']
	},
	
	// User
	'incompleteAccountError': {
		'title': 'Account activation incomplete',
		'text': ['It seems that you haven\'t completed all the necessary steps to activate your account.' ]
	},
	'emailNotConfirmedMessage': {
		'title': 'Email not confirmed yet',
		'message': ['Your email hasn\'t been confirmed yet. Please be sure to check and confirm your email before creating your new profile.']
	},
	'emailNotConfirmedError': {
		'title': 'Email not confirmed yet',
		'text': ['Your email hasn\'t been confirmed yet. Please confirm your email.']
	},
	'unlockedPostsMessage': {
		'title': 'Posts have been unlocked!',
		'text': ['You have successfully unlocked all posts.']
	},

	// Profile
	'profileInReviewMessage': {
		'title': 'Profile in review',
		'text': [
			'You\'ve done a great job on creating your first profile!',
			'It\'s now in review and it can take up to 12 hours to get a confirmation.',
			'Once that is done, you will be notified via email.'
		]
	},
	'profileNotConfirmedMessage': {
		'title': 'Profile not confirmed',
		'text': ['It seems that the profile you are trying to use hasn\'t been confirmed yet.']
	},
	'limitedProfileError': {
		'title': 'Action impossible',
		'text': ['It seems that this profile does not meet FindUpp\'s Profile Acceptance Rules and is limited. Click on \'View issues\' under the Profile section to resolve this.']
	},
	'raiseProfileTimeError': {
		'title': 'Profile already raised',
		'text': ['You have recently raised this profile. Please wait ' + keys.hoursToAllowProfileRaise + ' hours to raise again.']
	},
	'raisedProfileMessage': {
		'title': 'Profile successfully raised!',
		'text': ['Your profile has been successfully raised to the top. You can raise your profile again %repStr1%.']
	},
	'receiverDoesNotAcceptMessagesError': {
		'title': 'Message not sent',
		'text': ['This profile is pausing on messages. Try someone else, or get back again later.']
	},
	
	// Conversations
	'messageSentMessage': {
		'title': 'Message sent!',
		'text': ['Your message has been successfully sent.']
	},
	'conversationRemovedMessage': {
		'title': 'Conversation removed',
		'text': ['The conversation has been removed.']
	},
	
	// Feed
	'messageSubmittedMessage': {
		'title': 'Post created!',
		'text': [
			'Your post has been created. Findupp team will review it shortly. Once it is approved, it will appear in the public feed.'
		]
	},
	
	// Comment
	'commentSubmittedMessage': {
		'title': 'Comment added!',
		'text': [
			'Your comment has been successfully added.'
		]
	},
	
	// Settings
	'creditsSuccessfullyPurchased': {
		'title': 'Credits successfully purchased!',
		'text': ['You have successfully purchased additional credits.']
	}
};

export default messages;