import { STATE_RESET, SET_FILTER } from './../actions/types';

const initialState: {[key: string]: any} = {
	'country': '',
	'city': '',
	'type': '',
	'category': '',
	'interest': '',
	'searchPhrase': '',
	'postKind': ''
};

const updatedState = (state: {[key: string]: any} = initialState, action: {[key: string]: any} = {}) => {
	let data: {[key: string]: any} = {
		'country': action['country'],
		'city': action['city'],
		'type': action['dataType'],
		'category': action['category'],
		'interest': action['interest'],
		'searchPhrase': action['searchPhrase'],
		'postKind': action['postKind']
	};

	switch(action['type']) {

		case STATE_RESET: return initialState;

		case SET_FILTER: return data;
		
		default: return state;
	}
}

export default updatedState;