import React, { useEffect } from 'react';
import _ from 'lodash';

// Import components
import ProgressiveImg from './../progressive-img/progressive-img';

// Import styles
import './popup.scss';

interface props {
	'title'?: string,
	'image'?: string,
	'text'?: Array<any>,
	'active': boolean,
	'position'?: string,
	'transparency'?: number,
	'submitBtn'?: string,
	'onSubmit'?: any
	'closeBtn'?: string,
	'disabledSubmitBtn'?: boolean,
	'children'?: any,
	'onClose'?: any
};

const Popup = (props: props) => {
	useEffect(() => {
		if (props.active) {
			document.body.classList.add('overflow-hidden');
		} else {
			document.body.classList.remove('overflow-hidden');
		}
	}, [props.active]);

	if (!props.active) { return null; }

	return (
		<div className={'POPUP' + (props.active ? ' active' : '')}>
			<div className="POPUP__table">
				{
					props.onClose &&
					<div className={'POPUP__closeoverlay' + (props.transparency ? ' diff' + props.transparency : '')} onClick={props.onClose}></div>
				}

				<div className={'POPUP__table-cell' + (props.position ? ' ' + props.position : '')}>
					<div className="grid">
						<div className="row">
							<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
								<div className="POPUP__inner">
									<div className="POPUP__bgs">
										<div className="POPUP__bgs-bg1" style={{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg4.svg)'}}></div>
										<div className="POPUP__bgs-bg2" style={{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg3.svg)'}}></div>
									</div>

									<div className="POPUP__content">
										<div className="SECTION padding-wrap-md no-bottom-padding">
											<div className="grid">
												{
													props.title &&
													<div className="row">
														<div className="col-12">
															<div className="SECTION__title font-lg text-left">
																<div>
																	{ props.title }

																	{
																		props.onClose &&
																		<div className="POPUP__close">
																			<div className="POPUP__close-item" onClick={props.onClose}>
																				<div className="icon-plus-regular"></div>
																			</div>
																		</div>
																	}
																</div>
															</div>
														</div>
													</div>
												}
											</div>
										</div>

										{
											props.image &&
											<div className="SECTION no-bottom-padding">
												<div className="grid">
													<div className="row">
														<div className="col-12">
															<div className="POPUP__image">
																<ProgressiveImg
																	img={true}
																	showLoader={true}
																	shouldLoad={props.active}
																	lowQualitySrc=""
																	highQualitySrc={process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/el/' +  props.image}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										}

										{
											props.text &&
											<div className="SECTION no-bottom-padding">
												<div className="grid">
													<div className="row">
														<div className="col-12">
															{
																_.isArray(props.text) && !_.isEmpty(props.text) &&
																<div className="POPUP__messages">
																	{
																		_.map(props.text, (val1, i1) => {
																			if (/<("[^"]*"|'[^']*'|[^'">])*>/.test(val1)) {
																				return (
																					<div key={i1} dangerouslySetInnerHTML={{__html: val1}}></div>
																				)
																			} else {
																				return (
																					<p key={i1} dangerouslySetInnerHTML={{__html: val1}}></p>
																				)
																			}
																		})
																	}
																</div>
															}

															{
																!_.isArray(props.text) && !_.isEmpty(props.text) &&
																<div className="POPUP__messages" dangerouslySetInnerHTML={{__html: props.text}}></div>
															}
														</div>
													</div>
												</div>
											</div>
										}

										{ 
											props.children && 
											props.children
										}

										{
											(props.submitBtn || props.closeBtn) &&
											<div className="SECTION no-bottom-padding">
												<div className="grid">
													<div className="row">
														{
															props.closeBtn &&
															<div className="col-4">
																<span onClick={props.onClose} className="BUTTON diff1">
																	{ props.closeBtn }
																</span>
															</div>
														}

														{
															props.submitBtn &&
															<div className="col-8">
																<button onClick={props.onSubmit} className="BUTTON" disabled={props.disabledSubmitBtn}>
																	{ props.submitBtn }
																</button>
															</div>
														}
													</div>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Popup;