import { STATE_RESET, SET_GENERAL_TEXT } from './../actions/types';

const initialState: {[key: string]: any} = {};

const updatedState = (state: {[key: string]: any} = initialState, action: {[key: string]: any} = {}) => {
	let data: {[key: string]: any} = action['generalText'] || initialState;

	switch(action['type']) {
		case STATE_RESET: return initialState;

		case SET_GENERAL_TEXT: return data;
		
		default: return state;
	}
}

export default updatedState;