import { STATE_RESET, SET_LANG } from './../actions/types';

const initialState: string = 'en';

const updatedState = (state: string = initialState, action: {[key: string]: any} = {}) => {
	let data: string = action['lang'] || initialState;

	switch(action['type']) {
		case STATE_RESET: return initialState;

		case SET_LANG: return data;
		
		default: return state;
	}
}

export default updatedState;