import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import _ from 'lodash';

// Import middlewares
import api from './../../../other/middlewares/api';

// Import components
import PageHeader from './../../../components/page-header/page-header';
import Loader from './../../../components/loader/loader';
import Pagination from './../../../components/pagination/pagination';
import Popup from './../../../components/popup/popup';
import Input from './../../../components/input/input';
import Textarea from './../../../components/textarea/textarea';

interface props {};

const CmsUsers = (props: props) => {
	const initFilter: {[key: string]: any} = {'searchPhrase': ''}
	const initPopups: {[key: string]: any} = {
		'entryToEditId': '',
		'userToDeactivateId': '',
	}
	const initIds: {[key: string]: any} = {
		'expandedRowId': ''
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [users, setUsers] = useState<Array<any>>([]);
	const [usersPage, setUsersPage] = useState<number>(1);
	const [usersTotalPages, setUsersTotalPages] = useState<number>(0);

	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [ids, setIds] = useState<{[key: string]: any}>(initIds);
	const [entry, setEntry] = useState<string>('');
	const [filter, setFilter] = useState<{[key: string]: any}>(initFilter);
	const [errors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	const onGetUsers = useCallback((page: any, searchPhrase: any) => {
		setIsLoading(true);

		if (page === 1) {
			setUsers([]);
			setUsersPage(1);
			setUsersTotalPages(1);
		}

		api.get('/admin-get-users/' + page + (searchPhrase ? ('/' + searchPhrase) : '')).then((res) => {

			setIsLoading(false);
			setUsersPage(page);
			setUsers(res.data.entries);

			if (page === 1 && res.data?.total) {
				setUsersTotalPages(Math.ceil(res.data.total / res.data.entries.length));
			}

		}).catch((err) => {

			setIsLoading(false);
			setNotifications(err.response?.data?.messages);

		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onGetUsers(1, '');
	}, [onGetUsers])
	
	const getUser = (id: string) => {
		setIsLoading(true);
		
		api.get('/admin-get-user/' + id).then((res) => {

			// Replaced user with an updated version
			let stateClone = _.cloneDeep(users);
			let index = _.findIndex(stateClone, {'_id': res.data._id});

			stateClone[index] = res.data;

			setUsers(stateClone);
			setIsLoading(false);

		}).catch((err) => {

			setNotifications(err.response?.data?.messages);
			setIsLoading(false);

		});
	}

	const onGetEntryJSON = (id: string) => {
		setIsLoading(true);
		
		api.get('/admin-get-user/' + id).then((res) => {

			let editableFields: any = {
				'_id': res.data._id,
				'credits': res.data.credits,
				'isEmailConfirmed': res.data.isEmailConfirmed,
				'confirmationId': res.data.confirmationId,
				'invitationCodeShareable': res.data.invitationCodeShareable,
				'isDarkThemeEnabled': res.data.isDarkThemeEnabled,
				'showGuide': res.data.showGuide,
				'availableNetworks': res.data.availableNetworks,
				'availableProfiles': res.data.availableProfiles,
				'availableFavorites': res.data.availableFavorites,
				'firstName': res.data.firstName,
				'lastName': res.data.lastName
			}

			setEntry(JSON.stringify(editableFields, undefined, 4));
			setPopups({...initPopups, 'entryToEditId': res.data._id});
			setIsLoading(false);

		}).catch((err) => {

			setNotifications(err.response?.data?.messages);
			setIsLoading(false);

		});
	}

	const onEditUser = () => {
		setIsLoading(true);

		// Remove user
		api.put('/admin-edit-user', JSON.parse(entry)).then((res) => {			

			setPopups(initPopups);
			getUser(res.data._id);
			setIsLoading(false);

		}).catch((err) => {

			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
			setIsLoading(false);

		});
	}

	const onRemoveUser = async () => {
		setIsLoading(true);

		let userData: any = {
			'id': popups.userToDeactivateId
		}

		// Remove user
		api.put('/admin-deactivate-user', userData).then((res) => {

			setPopups(initPopups);
			onGetUsers(usersPage, filter.searchPhrase);
			setIsLoading(false);

		}).catch((err) => {

			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
			setIsLoading(false);

		});
	}

	return (
		<div className="CMSUSERS">
			<Loader active={isLoading} fixed={true} />

			<Popup
				title="Edit entry"
				submitBtn="Edit entry"
				closeBtn="Cancel"
				active={popups.entryToEditId !== ''}
				onSubmit={() => onEditUser()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION padding-wrap-sm">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="SECTION__item">
									<div className="FIELD">
										<Textarea
											value={entry}
											label="Entry JSON"
											placeholder="Enter text..."
											errors={errors.entry}
											onChange={(val: string) => setEntry(val)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title="Confirm user deactivation"
				text={["Are you sure you want to deactivate this user?"]}
				submitBtn="Confirm"
				closeBtn="Cancel"
				active={popups.userToDeactivateId !== ''}
				onSubmit={() => onRemoveUser()}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #12424635632']}
				closeBtn="Close"
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<PageHeader heading="Users" />
			
			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12 col-md-6">
							<div className="FIELD diff1">
								<Input
									value={filter.searchPhrase}
									label="Custom search"
									placeholder="Custom search by ID, name, email or invitation code..."
									type="text"
									errors={errors.searchPhrase}
									onChange={(val: number) => setFilter(prevState => ({...prevState.credits, 'searchPhrase': val}))}
								/>
							</div>
						</div>

						<div className="col-6 col-md-3">
							<button onClick={() => onGetUsers(1, filter.searchPhrase)} className="BUTTON">Search</button>
						</div>

						<div className="col-6 col-md-3">
							<button onClick={() => onGetUsers(1, '')} className="BUTTON diff1">Reset Filter</button>
						</div>
					</div>
				</div>	
			</div>

			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="CMSLAYOUT__table">
								<table>
									<thead>
										<tr>
											<th>
											</th>

											<th>
												<p>User ID</p>
											</th>

											<th>
												<p>First &amp; last name</p>
											</th>

											<th>
												<p>Email</p>
											</th>

											<th>
												<p>Profiles</p>
											</th>

											<th>
												<p>Credits</p>
											</th>

											<th>
												<p>Credits spent</p>
											</th>

											<th>
												<p>Invitation code used</p>
											</th>

											<th>
												<p>Invitation code shareable</p>
											</th>

											<th>
												<p>Email promotions allowed</p>
											</th>

											<th>
												<p>Email promotions sent</p>
											</th>

											<th>
												<p>Email app updates allowed</p>
											</th>

											<th>
												<p>Email app updates sent</p>
											</th>

											<th>
												<p>Email message notifications allowed</p>
											</th>

											<th>
												<p>Admin</p>
											</th>

											<th>
												<p>Available profiles</p>
											</th>

											<th>
												<p>Available networks</p>
											</th>

											<th>
												<p>Available favorites</p>
											</th>

											<th>
												<p>Created at</p>
											</th>

											<th>
												<p>Updated at</p>
											</th>

											<th>
												<p>Deleted at</p>
											</th>
										</tr>
									</thead>

									<tbody>
										{
											_.map(users, (val1, i1) => {
												let bg = '';

												switch (val1.isEmailConfirmed) {
													case false: bg = 'yellow'; break;
													case true: bg = 'green'; break;
													default: bg = '';
												}

												return (
													<tr key={i1} className={ids.expandedRowId === i1.toString() ? 'expanded': ''}>
														<td>
															<div className="cell">
																<div className={'color bg-' + bg}></div>
																
																<button onClick={() => setIds(prevState => ({...initIds, 'expandedRowId': (prevState.expandedRowId === i1.toString() ? '' : i1.toString())}))} className="BUTTON size1">{ ids.expandedRowId === i1.toString() ? 'Collapse' : 'Expand' }</button>
																<button onClick={() => onGetEntryJSON(val1._id)} className="BUTTON size1">Edit entry</button>
																<button onClick={() => setPopups({...initPopups, 'userToDeactivateId': val1._id})} className="BUTTON size1">Deactivate user</button>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1._id }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>
																	<strong>First name:</strong> { val1.firstName }<br />
																	<strong>Last name:</strong> { val1.lastName }
																</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.email }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.profiles?.[0]?.total ? val1.profiles?.[0].total : 0 }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.credits }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.creditsSpent }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.invitationCodeUsed || '-' }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.invitationCodeShareable || '-' }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.isEmailPromotionsAllowed ? 'Yes' : 'No' }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.emailPromotionsSent || 0 }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.isEmailAppUpdatesAllowed ? 'Yes' : 'No' }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.emailAppUpdatesSent || 0 }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.isEmailMessageNotificationsAllowed ? 'Yes' : 'No' }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.adminType || '-' }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.availableProfiles }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.availableNetworks }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.availableFavorites }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.createdAt && moment(val1.createdAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.updatedAt && moment(val1.updatedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.deletedAt && moment(val1.deletedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{
						usersTotalPages > 1 &&
						<div className="row">
							<div className="col-12">
								<Pagination
									currentPage={usersPage}
									totalPages={usersTotalPages}
									prevText="Prev"
									nextText="Next"
									onChangePage={(val: number) => onGetUsers(val, filter.searchPhrase)}
								/>
							</div>
						</div>
					}
				</div>
			</div>

		</div>
	);
}

export default CmsUsers;