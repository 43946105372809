import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, Scene } from 'react-scrollmagic';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { validateFields } from './../../other/helpers/validate-fields';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import configs
import { apiBasePath } from './../../other/configs/urls';
import messages from './../../other/configs/messages';

// Import components
import Loader from './../../components/loader/loader';
import Popup from './../../components/popup/popup';
// import PageHeader from './../../components/page-header/page-header';
import ProgressiveImg from './../../components/progressive-img/progressive-img';
import ProfileItem from './../../components/profile-item/profile-item';
import Guide from './../../components/guide/guide';
import Textarea from './../../components/textarea/textarea';

// Import styles
import './conversations.scss';

interface props {
	'pageId': string
}

const Conversations = (props: props) => {
	const navigate = useNavigate();
	
	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initReport: {[key: string]: any} = {
		'report': ''
	}
	const initPopups: {[key: string]: any} = {
		'conversationToRemoveId': '',
		'conversationToReportId': ''
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [text, setText] = useState<{[key: string]: any}>({});

	const [conversations, setConversations] = useState<Array<any>>([]);
	const [conversationsPage, setConversationsPage] = useState<number>(1);
	const [conversationsTotal, setConversationsTotal] = useState<number>(0);

	const [topProfiles, setTopProfiles] = useState<Array<any>>([]);
	const [clickedUrlCheck, setClickedUrlCheck] = useState<{[key: string]: any}>({});

	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [report, setReport] = useState<{[key: string]: any}>(initReport);
	const [errors, setErrors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
			onGetTopProfiles();
			onGetConversations(1);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	const onGetTopProfiles = async () => {
		await new Promise((resolve, reject) => {
			// Get profiles by user's filter settings
			api.get('/get-top-profiles/').then((res) => {
				setTopProfiles(res.data);
				resolve(true);
			}).catch((err) => {
				setNotifications(err.response?.data?.messages);
				resolve(false);
			});
		});
	};

	const onGetConversations = useCallback((page: any) => {
		setIsLoading(true);
		
		if (page === 1) {
			setConversations([]);
			setConversationsPage(1);
			setConversationsTotal(0);
		}

		api.get('/get-user-conversations/' + page).then((res) => {
			setIsLoading(false);
			setConversations(prevState => ([...prevState, ...res.data.entries]));
			setConversationsPage(page + 1);

			// Get count of total posts and first post's date from DB
			if (page === 1) {
				setConversationsTotal(res.data?.total || 0);
			}

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	}, []);

	const onConversationRemove = () => {
		setIsLoading(true);

		let messageDate: any = {
			'conversationId': popups.conversationToRemoveId
		}

		// Remove conversation
		api.post('/remove-conversation', messageDate).then((res) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(messages.conversationRemovedMessage);

			onGetConversations(1);

		}).catch((err) => {

			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onConversationReport = () => {
		setIsLoading(true);

		let stateClone: any = _.cloneDeep(report);

		// Validation
		let fieldErrors = validateFields(stateClone, ['report']);

		if (!_.isEmpty(fieldErrors)) {
			setIsLoading(false);
			setErrors(fieldErrors);

			return;
		}

		let reportData: any = {
			'id': popups.conversationToReportId,
			'type': 'Message',
			'report': report.report
		}

		// Report conversation
		api.post('/add-report', reportData).then((res) => {

			setPopups(initPopups);
			setReport(initReport);
			setNotifications(messages.itemReportedMessage);
			setIsLoading(false);

		}).catch((err) => {

			setPopups(initPopups);
			setReport(initReport);
			setNotifications(err.response?.data?.messages);
			setIsLoading(false);
		});
	}

	const onOpenExternalLink = () => {
		onAddActionStats(clickedUrlCheck.slug, {'type': 'click', 'value': clickedUrlCheck.url});

		let url = clickedUrlCheck.url;

		if (!url.match(/^https?:\/\//i)) {
			url = 'https://' + url;
		}

		setClickedUrlCheck({});

		window.open(url, '_blank');
	}

	const onAddActionStats = (slug: string, data: {[key: string]: any}) => {
		let actionStatsData: object = {
			'slug': slug,
			...data
		}

		api.post('add-action', actionStatsData);
	}

	if (_.isEmpty(text)) { return null; }

	return (
		<div className="CONVERSATIONS full-height">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
			
			<Loader active={isLoading} fixed={true} />

			<Guide
				title={text.guidePopup?.title}
				text={text.guidePopup?.text1}
				nextButton={text.guidePopup?.button1}
			/>

			<Popup
				title={text.reportConversationPopup?.title}
				submitBtn={text.reportConversationPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.conversationToReportId !== ''}
				onSubmit={() => onConversationReport()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="SECTION__item">
									<div className={'FIELD' + (errors.report ? ' error' : '')}>
										<Textarea
											value={report.report}
											placeholder={text.reportConversationPopup?.input1?.placeholder}
											label={text.reportConversationPopup?.input1?.label}
											onChange={(val: string) => setReport(prevState => ({...prevState, 'report': val}))}
										/>

										{
											errors.report &&
											<p className="error">
												{ errors.report }
											</p>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={text.removeConversationPopup?.title}
				submitBtn={text.removeConversationPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.conversationToRemoveId !== ''}
				onSubmit={() => onConversationRemove()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-12" dangerouslySetInnerHTML={{ __html: text.removeConversationPopup?.text1 }}></div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={generalText.externalLinkPopup?.title}
				closeBtn={generalText?.generalPopup?.button1?.text}
				submitBtn={generalText.externalLinkPopup?.button1?.text}
				active={!_.isEmpty(clickedUrlCheck)}
				onSubmit={() => onOpenExternalLink()}
				onClose={() => setClickedUrlCheck({})}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								generalText.externalLinkPopup?.text1 &&
								<div className="col-12">
									{
										parse(generalText.externalLinkPopup?.text1, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <strong>{ clickedUrlCheck.url }</strong>;
												}
											}
										})
									}
								</div>
							}
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				closeBtn={generalText?.generalPopup?.button1?.text}
				text={notifications?.text || ['Unhandled error - #363463564355']}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				{/*<PageHeader heading={text.pageTitle} />*/}
				
				{
					conversations?.length > 0 &&
					<div className="SECTION">
						<div className="grid">
							<div className="row">
								<div className="col-12 col-sm-8 push-sm-2 col-md-8 push-md-3 col-md-6">
									<div className="row">
										{
											_.map(conversations, (val1, i1) => {
												let userProfile = auth.id === val1.senderUserId ? val1.senderProfile : val1.receiverProfile;
												let otherProfile = auth.id !== val1.receiverUserId ? val1.receiverProfile : val1.senderProfile;

												let userProfileImage = '';

												if (userProfile?.images?.[0]?.path) {
													userProfileImage = apiBasePath + '/uploads/' + userProfile?.images[0].path;
												}

												let otherProfileImage = '';

												if (otherProfile?.images?.[0]?.path) {
													otherProfileImage = apiBasePath + '/uploads/' + otherProfile?.images[0].path;
												}

												return (
													<div key={i1} className="col-12">
														<div className="SECTION__item">
															<div className={'CONVERSATIONS__item' + (!val1.isRead && val1.receiverUserId === auth.id ? ' diff2' : ' diff1')}>
																<Link to={slugByPageId('conversation', lang, routes).replace(':id', val1._id.conversationId)} className="CONVERSATIONS__link"></Link>
																
																<div className="grid">
																	<div className="row">
																		<div className="col-12">
																			<div className="CONVERSATIONS__container">
																				<div className="CONVERSATIONS__images">
																					{
																						(userProfile && userProfile.isVisible && userProfile.status === 1) &&
																						<div className={'PROFILEIMAGE' + (!userProfile.slug ? ' diff1' : '')}>
																							{
																								userProfile.slug && 
																								<Link to={slugByPageId('profile', lang, routes).replace(':slug', userProfile.slug)} className="LINK"></Link>
																							}

																							<span className="PROFILEIMAGE__inner">
																								<span className={generalText.profileItem?.hiddenProfile?.icon}></span>
																								
																								<Scene>
																									{(progress: any, event: any) => (
																										<div className="image">
																											<ProgressiveImg
																												img={false}
																												showLoader={true}
																												shouldLoad={event.type === 'start'}
																												lowQualitySrc=""
																												highQualitySrc={userProfileImage}
																											/>
																										</div>
																									)}
																								</Scene>
																							</span>
																						</div>
																					}

																					{
																						(
																							!userProfile || 
																							!userProfile.isVisible || 
																							userProfile.status !== 1
																						) &&
																						<div className="PROFILEIMAGE diff1">
																							<span className="PROFILEIMAGE__inner">
																								<span className={generalText.profileItem?.hiddenProfile?.icon}></span>
																							</span>
																						</div>
																					}

																					{
																						(
																							otherProfile?.isVisible && 
																							otherProfile?.status === 1
																						) &&
																						<div className={'PROFILEIMAGE' + (!otherProfile.slug ? ' diff1' : '')}>
																							{
																								otherProfile.slug &&
																								<Link to={slugByPageId('profile', lang, routes).replace(':slug', otherProfile.slug)} className="LINK"></Link>
																							}
																							
																							<span className="PROFILEIMAGE__inner">
																								<span className={generalText.profileItem?.hiddenProfile?.icon}></span>
																								
																								<Scene>
																									{(progress: any, event: any) => (
																										<div className="image">
																											<ProgressiveImg
																												img={false}
																												showLoader={true}
																												shouldLoad={event.type === 'start'}
																												lowQualitySrc=""
																												highQualitySrc={otherProfileImage}
																											/>
																										</div>
																									)}
																								</Scene>
																							</span>
																						</div>
																					}

																					{
																						(
																							!otherProfile || 
																							!otherProfile.isVisible || 
																							otherProfile.status !== 1
																						) &&
																						<div className="PROFILEIMAGE diff1">
																							<span className="PROFILEIMAGE__inner">
																								<span className={generalText.profileItem?.hiddenProfile?.icon}></span>
																							</span>
																						</div>
																					}
																				</div>

																				<div className="CONVERSATIONS__content">
																					<div className="CONVERSATIONS__content-header">
																						{
																							(
																								userProfile?.isVisible && 
																								userProfile?.status === 1 &&
																								userProfile?.name
																							) &&
																							<Link to={slugByPageId('profile', lang, routes).replace(':slug', userProfile.slug)} className="LINK">{ userProfile.name }</Link>
																						}

																						{
																							(
																								!userProfile || 
																								!userProfile.isVisible || 
																								userProfile.status !== 1
																							) &&
																							<span>{ generalText.profileItem?.hiddenProfile?.title }</span>
																						}

																						<span> x </span>

																						{
																							(
																								otherProfile?.isVisible && 
																								otherProfile?.status === 1 &&
																								otherProfile?.name
																							) &&
																							<Link to={slugByPageId('profile', lang, routes).replace(':slug', otherProfile.slug)} className="LINK">{ otherProfile.name }</Link>
																						}

																						{
																							(
																								!otherProfile || 
																								!otherProfile.isVisible || 
																								otherProfile.status !== 1
																							) &&
																							<span>{ generalText.profileItem?.hiddenProfile?.title }</span>
																						}
																					</div>

																					{
																						val1.message &&
																						<div className="CONVERSATIONS__content-body">
																							<p>
																								{val1.message.length > 80 ? val1.message.substring(0, 80) + ' ...' : val1.message}
																							</p>
																						</div>
																					}

																					<div className="CONVERSATIONS__options">
																						{
																							generalText.removeLink?.text &&
																							<div onClick={() => setPopups(prevState => ({...prevState, 'conversationToRemoveId': val1._id.conversationId}))} className="CONVERSATIONS__options-item LINK">
																								{ generalText.removeLink?.text }
																							</div>
																						}

																						{
																							generalText.reportLink?.text &&
																							<div onClick={() => setPopups(prevState => ({...prevState, 'conversationToReportId': val1._id.conversationId}))} className="CONVERSATIONS__options-item LINK">
																								{ generalText.reportLink?.text }
																							</div>
																						}
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												)
											})
										}

										{
											conversations?.length > 0 && 
											(conversationsTotal > conversations.length) && 
											text.conversations?.footer?.button1?.text &&
											<div className="col-6 push-3">
												<div className="SECTION__item">
														<button onClick={() => onGetConversations(conversationsPage)} className="BUTTON">{ text.conversations?.footer?.button1?.text }</button>		
												</div>
											</div>
										}
										
										{
											(conversationsTotal <= conversations.length) && 
											text.conversations?.footer?.button1?.disabledText &&
											<div className="col-6 push-3">
												<div className="SECTION__item">
													<button className="BUTTON diff1" disabled={true}>{ text.conversations?.footer?.button1?.disabledText }</button>
												</div>
											</div>
										}
									</div>

								</div>
							</div>
						</div>
					</div>
				}
		
				{
					(
						!isLoading && 
						conversations?.length <= 0
					) &&
					<div className="SECTION text-center full-height">
						<div className="grid full-height">
							<div className="row full-height">
								<div className="col-12 full-height">
									<div className="SECTION__inner full-height no-padding">
										<div className="SECTION__inner-scroll align-content-vertical">
											<div className="SECTION__inner">
												{
													text.conversations?.noResults?.icon &&
													<div className="SECTION__icon">
														<div className={text.conversations?.noResults?.icon}></div>
													</div>
												}
												
												{
													text.conversations?.noResults?.title &&
													<div className="SECTION__title">{ text.conversations?.noResults?.title }</div>
												}
												
												{
													text.conversations?.noResults?.subtitle &&
													<div className="SECTION__subtitle">{ text.conversations?.noResults?.subtitle }</div>
												}
												
												{
													text.conversations?.noResults?.text &&
													<div dangerouslySetInnerHTML={{ __html: text.conversations?.noResults?.text }}></div>
												}

												{
													text.conversations?.noResults?.buttons?.length > 0 &&
													<div className="row">
														{
															_.map(text.conversations?.noResults?.buttons, (val1, i1) => {
																return (
																	<div key={i1} className={val1.gridClasses}>	
																		<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.link ? ' ' + val1.link : '')}>{ val1.text }</Link>
																	</div>
																)
															})
														}
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				{
					topProfiles?.length > 0 &&
					<div className="SECTION CONVERSATIONS__slider overflow-hidden">
						<div className="grid">
							<div className="row">
								<div className="col-12">
									{
										text.topProfiles?.heading &&
										<div className="SLIDER__arrowssmall">
											<div className="SECTION__title font-lg">{ text.topProfiles?.heading }</div>

											<div className="SLIDER__arrowssmall-arrows">
												<div className="SLIDER__arrowssmall-item prev">
													<span className={generalText.other?.sliderArrowsSmall}></span>
												</div>

												<div className="SLIDER__arrowssmall-item next">
													<span className={generalText.other?.sliderArrowsSmall}></span>
												</div>
											</div>
										</div>
									}

									<Swiper
										speed={300}
										className="top-profiles-slider"
										modules={[Navigation]}
										slidesPerView={4.5}
										centeredSlides={false}
										loop={false}
										spaceBetween={10}
										autoplay={false}
										navigation={{
											'prevEl': '.CONVERSATIONS__slider .SLIDER__arrowssmall-item.prev',
											'nextEl': '.CONVERSATIONS__slider .SLIDER__arrowssmall-item.next',
										}}
										breakpoints={{
											768: {
												'slidesPerView': 8,
												'spaceBetween': 20
											}
										}}
									>
										{
											_.map(topProfiles, (val1, i1) => {
												return (
													<SwiperSlide key={i1}>
														<div className="SECTION__item">
															<ProfileItem
																layout="xs"
																isVisible={true}
																userId={val1.userId}
																profileId={val1._id}
																slug={val1.slug}
																images={val1.images}
																raisedAt={val1.raisedAt}
																activeAt={val1.activeAt}
																clickedUrlCheck={(val: {[key: string]: any}) => setClickedUrlCheck(val)}
																setNotifications={(val: {[key: string]: any}) => setNotifications(val)}
															/>
														</div>
													</SwiperSlide>
												)
											})
										}
									</Swiper>
								</div>
							</div>
						</div>
					</div>
				}
			</Controller>
		</div>
	);
}

export default Conversations;