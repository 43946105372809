import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Loader from './../../components/loader/loader';

interface props {
	'pageId'?: string
}

const Response = (props: props) => {
	const navigate = useNavigate();
	const { code }: any = useParams();

	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [text, setText] = useState<{[key: string]: any}>({});

	useEffect(() => {
		if (!props.pageId) { return; }

		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	useEffect(() => {
		if (!code || !props.pageId) { return; }

		setIsLoading(true);

		api.get('/get-qr/' + code).then((res) => {
			setIsLoading(false);
			
			if (res.data?.type === 'profile') {
				onAddActionStats(res.data.value, {'type': 'scan'});
				return navigate(slugByPageId('profile', lang, routes).replace(':slug', res.data.value), {'replace': true});
			}

			return navigate(res.data.value, {'replace': true});

		}).catch((err) => {
			setIsLoading(false);

			return navigate(slugByPageId('about1', lang, routes), {'replace': true});
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onAddActionStats: any = (slug: string, data: {[key: string]: any}) => {
		let actionStatsData: object = {
			'slug': slug,
			...data
		}

		api.post('add-action', actionStatsData);
	}

	return (
		<div className="QR">
			<Helmet
				titleTemplate={generalText?.siteTitle + ' | %s'}
				defaultTitle={generalText?.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
			
			<Loader active={isLoading} fixed={true} />
			
			<div className="SECTION padding-wrap-xl bg-diff1 text-center">
				<div className="grid">
					<div className="row">
						<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
							{
								text.icon &&
								<div className="SECTION__icon">
									<div className={text.icon}></div>
								</div>
							}

							{
								text.title &&
								<div className="SECTION__title">{ text.title }</div>
							}

							{
								text.subtitle &&
								<div className="SECTION__subtitle">{ text.subtitle }</div>
							}

							{
								text.text &&
								<div dangerouslySetInnerHTML={{ __html: text.text }}></div>
							}

							{
								text.buttons?.length > 0 &&
								<div className="col-12">
									<div className="SECTION__item">
										<div className="row">
											{
												_.map(text.buttons, (val1, i1) => {
													return (
														<div key={i1} className={val1.gridClasses}>
															<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</Link>
														</div>
													)
												})
											}
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Response;