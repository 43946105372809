import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';
import { validateFields } from './../../other/helpers/validate-fields';

// Import components
import Loader from './../../components/loader/loader';
import Popup from './../../components/popup/popup';
import Input from './../../components/input/input';

interface props {
	'pageId'?: string
}

const EmailConfirmation = (props: props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initConfirmationId: {[key: string]: any} = {
		'confirmationId': ''
	}
	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [confirmationId, setConfirmationId] = useState<{[key: string]: any}>(initConfirmationId);
	const [text, setText] = useState<{[key: string]: any}>({});
	const [errors, setErrors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		if (!props.pageId) { return; }

		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);

		}).catch((err) => {

			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	const onSubmitForm = (e: any) => {
		e.preventDefault();

		setIsLoading(true);

		let stateClone: any = _.cloneDeep(confirmationId);

		// Validation
		let fieldErrors = validateFields(stateClone, ['user']);

		if (!_.isEmpty(fieldErrors)) {
			setIsLoading(false);
			setErrors(fieldErrors);

			return;
		}

		let confirmationData: any = {
			'confirmationId': stateClone.confirmationId
		}

		api.post('/confirm-user-email', confirmationData).then((res) => {
			setIsLoading(false);
			dispatch(setCurrentUser({...auth, 'isEmailConfirmed': true}));

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	return (
		<div className="EMAILCONFIRMATION full-height">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
			
			<Loader active={isLoading} fixed={true} />

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #56234233321']}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>
			
			<div className="SECTION text-center full-height">
				<div className="SECTION__item full-height">
					<div className="SECTION__inner full-height no-padding">
						<div className="SECTION__inner-scroll align-content-vertical">
							<div className="SECTION__inner">
								<div className="grid full-height">
									<div className="row full-height">
										<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3 full-height">
											{
												text.icon &&
												<div className="SECTION__icon">
													<div className={text.icon}></div>
												</div>
											}

											{
												text.title &&
												<div className="SECTION__title">{ text.title }</div>
											}

											{
												text.subtitle1 && !auth.isEmailConfirmed &&
												<div className="SECTION__subtitle" dangerouslySetInnerHTML={{ __html: text.subtitle1.replace('%repStr1%', auth.email) }}></div>
											}

											{
												text.subtitle2 && auth.isEmailConfirmed &&
												<div className="SECTION__subtitle" dangerouslySetInnerHTML={{ __html: text.subtitle2.replace('%repStr1%', auth.email) }}></div>
											}

											{
												text.text &&
												<div dangerouslySetInnerHTML={{ __html: text.text }}></div>
											}

											<form onSubmit={(e: any) => onSubmitForm(e)} className="EDITPROFILE">
												<div className="row">
													{
														text.input1 && !auth.isEmailConfirmed &&
														<div className="col-12">
															<div className="FIELD diff1">
																<Input
																	value={confirmationId.confirmationId}
																	label={text.input1?.label}
																	placeholder={text.input1?.placeholder}
																	type="text"
																	errors={errors.confirmationId}
																	onChange={(val: {[key: string]: any}) => {
																		setConfirmationId(prevState => ({...prevState, 'confirmationId': val}))
																	}}
																/>
															</div>
														</div>
													}

													{
														text.buttons?.length > 0 &&
														<div className="col-12">
															<div className="SECTION__item">
																<div className="row">
																	{
																		_.map(text.buttons, (val1, i1) => {
																			return (
																				<div key={i1} className={val1.gridClasses}>
																					{
																						val1.isEmailConfirmation && !auth.isEmailConfirmed &&
																						<button type="submit" className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</button>
																					}

																					{
																						!val1.isEmailConfirmation && auth.isEmailConfirmed &&
																						<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</Link>
																					}
																				</div>
																			)
																		})
																	}
																</div>
															</div>
														</div>
													}
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EmailConfirmation;